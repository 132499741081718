import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
function UserDetailsForm() {
  const [user, setUser] = useState();
  const phone = localStorage.getItem("phone");
  const [order_id, setOrder_id] = useState("");
  const [tracking_id, setTracking_id] = useState("");
  const [bank_ref_no, setBank_ref_no] = useState("");
  const [payment_mode, setPayment_mode] = useState("");
  const [billing_name, setBilling_name] = useState("");
  const [billing_address, setBilling_address] = useState("");
  const [billing_city, setBilling_city] = useState("");
  const [billing_state, setBilling_state] = useState("");
  const [billing_zip, setBilling_zip] = useState("");
  const [billing_country, setBilling_country] = useState("");
  const [billing_tel, setBilling_tel] = useState("");
  const [billing_email, setBilling_email] = useState("");
  const [trans_date, setTrans_date] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {
    axios
      .get(`http://localhost:4000/user/payinfo/?phone=${phone}`)
      .then((response) => {
        const userData = response.data.Result[0]; // Get the user data from the "Result" array
        setUser(userData);
        setOrder_id(userData.order_id);
        setTracking_id(userData.tracking_id);
        setBank_ref_no(userData.bank_ref_no);
        setPayment_mode(userData.payment_mode);
        setBilling_name(userData.billing_name);
        setBilling_address(userData.billing_address);
        setBilling_city(userData.billing_city);
        setBilling_state(userData.billing_state);
        setBilling_zip(userData.billing_zip);
        setBilling_country(userData.billing_country);
        setBilling_tel(userData.billing_tel);
        setBilling_email(userData.billing_email);
        setTrans_date(userData.trans_date);
        setAmount(userData.amount);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [phone]);

  if (!user) {
    return <p>Loading user details...</p>;
  }

  return (
    <table className="w-full text-xl">
      <thead>
        <tr>
          <th className="px-32"></th>
          <th className="px-32"></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr className="border-b-2" key="order_id">
          <td className="py-2">Order ID</td>
          <td className="py-2">{order_id}</td>
        </tr>
        <tr className="border-b-2" key="tracking_id">
          <td className="py-2">Tracking ID</td>
          <td className="py-2">{tracking_id}</td>
        </tr>
        <tr className="border-b-2" key="bank_ref_no">
          <td className="py-2">Bank Reference No</td>
      <td className="py-2">{ bank_ref_no }</td>
    </tr>
    <tr className="border-b-2" key="payment_mode">
      <td className="py-2">Payment Mode</td>
      <td className="py-2">{ payment_mode }</td>
      
    </tr>
    <tr className="border-b-2" key="billing_name">
      <td className="py-2">Billing Name</td>
      <td className="py-2">{ billing_name }</td>
      
    </tr>
    <tr className="border-b-2" key="billing_address">
      <td className="py-2">Billing Address</td>
      <td className="py-2">{ billing_address }</td>
      
    </tr>
    <tr className="border-b-2" key="billing_city">
      <td className="py-2">Billing city</td>
      <td className="py-2">{ billing_city }</td>
      
    </tr>
    <tr className="border-b-2" key="billing_state">
      <td className="py-2">Billing state</td>
      <td className="py-2">{ billing_state }</td>
      
    </tr>
    <tr className="border-b-2" key="billing_zip">
      <td className="py-2">Billing Postal code</td>
      <td className="py-2">{ billing_zip }</td>
      
    </tr>
    <tr className="border-b-2" key="billing_country">
      <td className="py-2">Billing Country</td>
      <td className="py-2">{ billing_country }</td>
      
    </tr>
    <tr className="border-b-2" key="billing_tel">
      <td className="py-2">Billing Phone Number</td>
      <td className="py-2">{ billing_tel }</td>
      
    </tr>
    <tr className="border-b-2" key="billing_email">
      <td className="py-2">Billing Email-Id</td>
      <td className="py-2">{ billing_email }</td>
      
    </tr>
    <tr className="border-b-2" key="trans_date">
      <td className="py-2">Transaction Date</td>
      <td className="py-2">{ trans_date }</td>
      
    </tr>
    <tr className="border-b-2" key="amount">
      <td className="py-2">Amount</td>
      <td className="py-2">{ amount }</td>
      
    </tr>
  </tbody>
</table>

  );
}

export default UserDetailsForm;
