import { useState } from "react";
import ImageOne from "../assests/tel_orange.png";
import { Link } from "react-router-dom";
import { logout } from "../auth/AuthSlice";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

// import ContactForm from "../page/ContactForm";
export default function NavDash() {
  const [navbar, setNavbar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const singinoutHandler = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_Name");
    localStorage.removeItem("phone");
    showSuccess("Logged out, Thank you");
    navigate("/", { replace: true });
  };
  const showSuccess = (resMessage) => {
    Swal.fire({
      title: "Success",
      text: resMessage,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  return (
    <nav className="w-full bg-black   shadow sticky top-0 z-50  ">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-3 ">
        <div>
          <div className="flex items-center justify-between  py-2 md:py-2  space-y-8 md:flex md:space-x-6 md:space-y-0">
            <div>
              <Link to={``}>
                <img
                  className="w-25 h-12 rounded-full mr-4 ... rounded-full mx-auto "
                  src={ImageOne}
                  alt="Profile"
                />
              </Link>
            </div>

            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-gray-200 hover:text-indigo-200">
                <Link
                  to={`/dashboard`}
                  className="text-gray-200 hover:text-indigo-200"
                >
                  Dashboard
                </Link>{" "}
              </li>
              <li className="text-gray-200 hover:text-indigo-200">
                <Link
                  to={`/profile`}
                  className="text-gray-200 hover:text-indigo-200"
                >
                  Profile
                </Link>{" "}
              </li>
              <li className="text-gray-200 hover:text-indigo-200">
                <Link
                  to={`/bnpl`}
                  className="text-gray-200 hover:text-indigo-200"
                >
                  BNPL
                </Link>{" "}
              </li>
              <li className="text-gray-200 hover:text-indigo-200">
                <Link
                  to={`/cancle`}
                  className="text-gray-200 hover:text-indigo-200"
                >
                  Cancel
                </Link>
              </li>
              <li className="text-gray-200 hover:text-indigo-200">
                <a
                  href="/home"
                  className="flex items-center p-2 space-x-3 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                    />
                  </svg>
                  <span>
                    <button onClick={singinoutHandler}>Logout</button>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
