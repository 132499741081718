import React from "react";
import ImageOne from "../assests/tel_orange.png";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const Newfooter = () => {
  const y = new Date();
  let year = y.getFullYear();

  return (
    <footer className="bg-black dark:bg-gray-800">
      <div className="container px-10 py-8 mx-auto">
        

        <div className="sm:flex sm:items-center sm:justify-between">
          <p className="text-sm text-gray-400">
            © Copyright {year}. All Rights Reserved.
          </p>
          <p className="text-sm text-gray-400">
            <span>
              <Link to={"/privacy"} className=" text-sm text-gray-400">
                Privacy & Policy
              </Link>
            </span>{" "}
            |{" "}
            <span>
              <Link to={"/term"} className=" text-sm text-gray-400">
                Terms And Condition
              </Link>
            </span>{" "}
            |{" "}
            <span>
              <Link to={"cancelpoly"} className=" text-sm text-gray-400">
                Cancellation Policy
              </Link>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};
