import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PendingIcon from '@mui/icons-material/Pending';
import { logout } from "../auth/AuthSlice";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ApiData from "../Api/ApiData";
import { Email } from "@mui/icons-material";

export default function Editprof() {


  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const singinoutHandler = () => {
    dispatch(logout());
    showSuccess("Thank you");
    navigate("/", { replace: true });
  };
  const showSuccess = (resMessage) => {
    Swal.fire({
      title: "Success",
      text: resMessage,
      icon: "success",
      confirmButtonText: "OK",
    });
  };
  const showError = (resMessage) => {
    Swal.fire({
      text: resMessage,
      icon: "error",
      title: "Oops...",
      confirmButtonText: "Error",
    });
  };

  
   const [data ,setData]=React.useState({});
  //const Email="rakeshtest@sal-bio.science"
   React.useEffect(() => {
    // Update the document title using the browser API
    LoginHandler()
  },[]);
  const LoginHandler = async (e) => {
      try {
        const res = await ApiData.get(
          `/user/userInfo/all/${Email}`,
          {
            headers: {
              "access-control-allow-origin" : "*",
              "Content-type": "application/json; charset=UTF-8"
            }

          }
        );
        //let resJson = await res.json();
        if (res.status === 200) {
          //console.log("hello");
          setData(res.data);
          showSuccess(res.data.message);
          // setMessage("User created successfully")
        } //else {
          // setMessage("Some error occured");
        //}
      } catch (err) {
        console.log(err);
        showError(err.res.data.message);

      }
    }
    console.log(data);

  return (
    <>
      <div>
        <div className="flex flex-col h-screen p-3 bg-orange-300 shadow w-60 h-full">
          <div className="space-y-3">
            
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center py-4">
                <button
                  type="submit"
                  className="p-2 focus:outline-none focus:ring"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </span>
              <input
                type="search"
                name="Search"
                placeholder="Search..."
                className="w-full py-2 pl-10 text-sm rounded-md focus:outline-none"
              />
            </div>
            <div className="flex-1">
              <ul className="pt-2 pb-4 space-y-1 text-">
                <li className="rounded-sm">
                  <a
                    href="/accountdetails"
                    className="flex items-center p-2 space-x-3 rounded-md"
                  >
                    <AccountCircleIcon />
                    <span>Account Details</span>
                  </a>
                </li>
                <li className="rounded-sm">
                  <a
                    href="https://telomr.simplybook.me/v2/"
                    className="flex items-center p-2 space-x-3 rounded-md"
                  >
                    <PendingIcon />
                    <span>Outstanding Amount</span>
                  </a>
                </li>
                <li className="rounded-sm">
                  <a
                    href="/cancle"
                    className="flex items-center p-2 space-x-3 rounded-md"
                  >
                    <AccountBalanceWalletIcon />
                    <span> Due balance </span>
                  </a>
                </li>
                {/* <li className="rounded-sm">
                                <a
                                    href="#"
                                    className="flex items-center p-2 space-x-3 rounded-md"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                                        />
                                    </svg>
                                    <span>Privacy Policy</span>
                                </a>
                            </li>
                            <li className="rounded-sm">
                                <a
                                    href="#"
                                    className="flex items-center p-2 space-x-3 rounded-md"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                    </svg>
                                    <span>Terms & Conditions</span>
                                </a>
                            </li> */}
                {/* <li className="rounded-sm">
                  <a
                    href="/home"
                    className="flex items-center p-2 space-x-3 rounded-md"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                      />
                    </svg>
                    <span>
                      <button onClick={singinoutHandler}>Logout</button>
                    </span>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="container mx-auto mt-12">
                <div className="grid grid-cols-1 gap-6 mb-6 lg:grid-cols-3">
                    <div className="w-full px-4 py-5 bg-white rounded-lg shadow">
                        <div className="text-sm font-medium text-gray-500 truncate">
                            Total users
                        </div>
                        <div className="mt-1 text-3xl font-semibold text-gray-900">
                            12,00
                        </div>
                    </div>
                    <div className="w-full px-4 py-5 bg-white rounded-lg shadow">
                        <div className="text-sm font-medium text-gray-500 truncate">
                            Total Profit
                        </div>
                        <div className="mt-1 text-3xl font-semibold text-gray-900">
                            $ 450k
                        </div>
                    </div>
                    <div className="w-full px-4 py-5 bg-white rounded-lg shadow">
                        <div className="text-sm font-medium text-gray-500 truncate">
                            Total Orders
                        </div>
                        <div className="mt-1 text-3xl font-semibold text-gray-900">
                            20k
                        </div>
                    </div>
                </div>
            </div> */}
      </div>
    </>
  );
}
