
import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import UserDetailsForm from "./test";
import Personalinfo from "./Personalinfo";
import NavDash from "./NavDash";
import { Newfooter } from "../components/Newfooter";
import PatientsDetailsForm from "./Patient";

function MyTabs() {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: "Personal Information ",
      content: <div><Personalinfo /></div>,
    },
    {
      title: "Patient Information ",
      content: <div><PatientsDetailsForm /></div>,
    },
    {
      title: "Payment Information ",
      content: <div><UserDetailsForm /></div>,
    }
    
  ];

  return (
    <>
     < NavDash />
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
     
      <Tab.Group onChange={(index) => setActiveTab(index)}>
        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                `flex-1 ${selected ? "bg-blue-900 text-white" : "text-blue-900"} rounded-xl font-medium text-center py-2.5 cursor-pointer`
              }
            >
              {tab.title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {tabs.map((tab, index) => (
            <Tab.Panel key={index} className="bg-white rounded-xl p-3">
              {tab.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
    <Newfooter />
    </>
  );
}

export default MyTabs;
