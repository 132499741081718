import React, { useState, useEffect } from "react";
import ImageOne from "../assests/tel_orange.png";
export default function Dashheader() {
  const [first_name, setFirst_name] = useState("");
  const [user_id, setUser_id] = useState("");
  useEffect(() => {
    const storedFirst_name = localStorage.getItem("first_name");
    if (storedFirst_name) {
      setFirst_name(storedFirst_name);
    }
    const storedUser_id = localStorage.getItem("user_id");
    if (storedUser_id) {
      setUser_id(storedUser_id);
    }
  }, []);
  return (
    <div className="2xl:container 2xl:mx-auto ">
      <div className="bg-black text-white rounded shadow-lg py-5 px-7">
        <nav className="flex justify-between">
          <div className="flex items-center space-x-3 lg:pr-16 pr-6">
            <img
              className="cursor-pointer"
              width={72}
              height={56}
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              src={ImageOne}
              alt="Profile"
            />
            {/* <h2 className="font-normal text-2xl leading-6 text-gray-800">OvonRueden</h2> */}
          </div>
          {/* For medium and plus sized devices */}
          <ul className="hidden md:flex flex-auto space-x-2">
            <li className={` font-normal text-xl leading-5 shadow-md rounded`}>
              <span className="mt-4">Welcome:-{first_name}</span> <br />
              <span className="mt-4 py-4">Customer ID:-{user_id}</span>
            </li>
            {/* <li onClick={()=>selected(1)} className={`${style[1] ? 'text-white bg-indigo-600' : 'text-gray-600 border border-white bg-gray-50'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer px-3 py-2.5 font-normal text-xs leading-3 shadow-md rounded`}>Arts</li> */}
          </ul>
          <div className=" flex space-x-5 justify-center items-center pl-2">
            <div className="">
              <span>Contact :- +919019363833</span>
              <div className=" w-1.5 h-1.5 bg-indigo-700 rounded-full absolute -top-1 -right-1 m-auto shadow-lg" />
            </div>

            <span>
              Email:-{" "}
              <span className="text- lowercase ">connect@telomr.life</span>
            </span>
          </div>
        </nav>
        {/* for smaller devcies */}
      </div>
    </div>
  );
}
