import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import kyc from "../assests/kyc.png"
import { Newfooter } from "../components/Newfooter";
import { logout } from "../auth/AuthSlice";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import Dashheader from "../components/Dashheader";
import bnpl from "../assests/BNPL.png"

export default function Sidebar() {
  const showSuccess = (resMessage) => {
    Swal.fire({
      title: "Success",
      text: resMessage,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const singinoutHandler = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_Name");
    localStorage.removeItem("phone");
    showSuccess("Logged out, Thank you");
    navigate("/", { replace: true });
  };
  

  return (
    <>
      <div>
        <Dashheader />
        <div className="flex flex-col h-screen p-3 bg-orange-300 shadow w-60 h-full">
          <div className="space-y-3">
            <div className="flex items-center">
              <h2 className="text-3xl">Dashboard</h2>
            </div>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center py-4">
                <button
                  type="submit"
                  className="p-2 focus:outline-none focus:ring"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </span>
              <input
                type="search"
                name="Search"
                placeholder="Search..."
                className="w-full py-2 pl-10 text-sm rounded-md focus:outline-none"
              />
            </div>
            <div className="flex-1">
              <ul className="pt-2 pb-4 space-y-1 text-">
                <li className="rounded-sm">
                  <Link
                    to={`/profile`}
                    className="flex items-center p-2 space-x-3 rounded-md"
                  >
                    <AccountCircleIcon />
                    <span>Profile</span>
                  </Link>
                </li>
                <li className="rounded-sm">
                  <a
                    href="https://wa.me/+919019363833"
                    target="_blank"
                    className="flex items-center p-2 space-x-3 rounded-md" rel="noreferrer"
                  >
                    <BookOnlineIcon />
                    <span>Appointment</span>
                  </a>
                </li>
                <li className="rounded-sm">
                  <Link
                    to={`/cancle`}
                    className="flex items-center p-2 space-x-3 rounded-md"
                  >
                    <CancelIcon />
                    <span>Cancel</span>
                  </Link>
                </li>
                <li className="rounded-sm">
  <a
    href="https://wa.me/+919019363833"
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center p-2 space-x-3 rounded-md"
  >
    <img
      src={bnpl}
      alt="Kyc"
      width="20"
      height="20"
      className="rounded-sm"
    />
    <span>BNPL</span>
  </a>
</li>

                <li className="rounded-sm">
  <Link
    to={`/kyc`}
    className="flex items-center p-2 space-x-3 rounded-md"
  >
    <img
      src={kyc}
      alt="Kyc"
      width="20"
      height="20"
      className="rounded-sm"
    />
    <span>Kyc</span>
  </Link>
</li>


                <li className="rounded-sm">
                  <a
                    href="/home"
                    className="flex items-center p-2 space-x-3 rounded-md"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                      />
                    </svg>
                    <span>
                      <button onClick={singinoutHandler}>Logout</button>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <Newfooter />
      </div>
    </>
  );
}
