/* This example requires Tailwind CSS v2.0+ */
import React, { useRef } from "react";
import { Popover } from "@headlessui/react";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { IconButton } from "@material-tailwind/react"
import img1 from "../assests/icon1.png";
import img3 from "../assests/icon3.png";
import img5 from "../assests/icon5.png";
import img6 from "../assests/icon6.png";
import blog1 from "../assests/blog1.png"
import blog2 from "../assests/blog2.png"
import blog3 from "../assests/blog3.png"
import blog4 from "../assests/blog4.png"
import { Footer } from "./Footer";
import { Link } from "react-router-dom";
import telomrimg1 from "../assests/telomrimg1.gif";
import Telomrimg4 from "../assests/Telomrimg4 (2).png";
// import Telomrimg5 from "../assests/Telomrimg5.svg"
import Telomrimg6 from "../assests/Telomrimg6.jpg";
//import { Whatsapp } from "./Whatsapp";
import Onco from "../assests/onco.png";
import Upload from "../assests/Finance.png";
import Cashback from "../assests/Cashback.png";
import Meds from "../assests/meds.jpg";
import Wellness from "../assests/Well.jpg";
import NavBar from "./NavBar";

// import CookieConsent from "react-cookie-consent";

export default function LandingPage() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8kc1i3s",
        "template_9m1ls2x",
        form.current,
        "eXilUDiWed5RNbvW0"
      )
      .then(
        () => {
          toast("Thankyou for Subscribing Us", {
            type: "success",
          });
        },
        (error) => {
          toast.error(error.text);
        }
      );
    e.target.reset();
  };
  return (
    
    <div className="">
      <NavBar />
      <div className="relative  overflow-hidden scroll-smooth hover:scroll-auto">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <Popover>
              <div className="relative pt-6 px-4 sm:px-6 lg:px-8"></div>
            </Popover>

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-5xl sm:tracking-tight md:text-5xl md:tracking-tight">
                  <span className="block xl:inline">Telomr </span>
                  <span className="block text-orange-600 xl:inline ">
                    A Unique cancer care Platform
                  </span>
                </h1>
                <p className="mt-8 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 ">
                  Telomr is a novel, health-tech platform connecting innovative
                  medicine with the right end user.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md ">
                    <button
                      type="button"
                      className="bg-gradient-to-r w-28 h-12  rounded-md from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 ..."
                    >
                      <Link
                        to={`/contact_us`}
                        className="text-gray-200 hover:text-indigo-200"
                      >
                        ContactUs
                      </Link>{" "}
                    </button>
                    
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 ">
          <img
            className="h-56 w-full  sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={Telomrimg6}
            alt=""
          />
        </div>
      </div>
      <div className="2xl:mx-auto 2xl:container mx-4 py-16">
        <div className=" w-full  relative flex items-center justify-center">
          <div className="bg-orange-500 bg-opacity-60 md:my-16 lg:py-16 py-10 w-full md:mx-24 md:px-12 px-4 flex flex-col items-center justify-center relative z-40">
            <h1 className="text-4xl font-semibold leading-9 text-white text-center">
              Who We Are
            </h1>
            <p className="text-base leading-normal text-center text-white mt-6">
              Telomr is a Complete Cancer-Care Aggregator system, bringing life
              changing therapies and benefits to Cancer Patients, Our platform
              connects the right patient with the right innovation and provides
              a social equity in accessing better healthcare, Meet the experts,
              service providers, support groups & more all from the comfort of
              your home..
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gray-200 h-full w-full ">
        <div id="/services" className="mb-8 text-center ">
          <h2 className="text-4xl py-8 font-bold text-orange-600">
            Telomr Offering
          </h2>
        </div>
        <div className="lg:grid lg:grid-cols-5 lg:gap-x-2 grid gap-2  pb-2 md:grid-cols-3 ">
          <div className="p-4 text-gray-800 sm:pr-2 sm:pl-2 rounded-lg shadow-md bg-white-300 ... ">
            <div className="flex flex-col items-center justify-center">
              <div className="w-32 h-32 overflow-hidden  ">
                <img
                  src={Onco}
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div>
              <div className="pt-4">
                <h3 className="text-center font-semibold text-xl text-gray-800">
                  Best Oncologist
                </h3>
              </div>
              <div className="pt-4">
                <h3 className="text-center text-gray-800 text-xs mx-4">
                  To help you out, we bring together the best doctors in medical
                  radiation & surgical oncology on one platform
                </h3>
              </div>
            </div>
          </div>
          <div className="p-4 text-gray-800 sm:pr-2 sm:pl-2 rounded-lg shadow-md bg-white-300 ... ">
            <div className="flex flex-col items-center justify-center">
              <div className="w-32 h-32 overflow-hidden ">
                <img
                  src={Upload}
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div>
              <div className="pt-4">
                <h3 className="text-center font-semibold text-xl text-gray-800">
                  Buy Now Pay Later
                </h3>
              </div>
              <div className="pt-4">
                <h3 className="text-center text-gray-800 text-xs mx-4">
                  Buy Now Pay Later (BNPL) will help you to get short term
                  Financial support to make purchases and pay in future without
                  any interest
                </h3>
              </div>
            </div>
          </div>
          <div className="p-4 text-gray-800 sm:pr-2 sm:pl-2 rounded-lg shadow-md bg-white-300 ... ">
            <div className="flex flex-col items-center justify-center">
              <div className="w-32 h-32 overflow-hidden ">
                <img
                  src={Cashback}
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div>
              <div className="pt-4">
                <h3 className="text-center  font-semibold text-xl text-gray-800">
                  Get Cashback
                </h3>
              </div>
              <div className="pt-4">
                <h3 className="text-center text-gray-800 text-xs mx-4">
                  It will help you to get Cashback on your every bill
                </h3>
              </div>
            </div>
          </div>
          <div className="p-4 text-gray-800 sm:pr-2 sm:pl-2 rounded-lg shadow-md bg-white-300 ... ">
            <div className="flex flex-col items-center justify-center">
              <div className="w-32 h-32 overflow-hidden ">
                <img
                  src={Meds}
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div>
              <div className="pt-4">
                <h3 className="text-center font-semibold text-xl text-gray-800">
                  Medicine
                </h3>
              </div>
              <div className="pt-4">
                <h3 className="text-center text-gray-800 text-xs mx-3">
                  Redefining supply chain expectation for health system
                  providing patient-focused solutions & world class distribution
                  all from a trusted partner you can count on
                </h3>
              </div>
            </div>
          </div>
          <div className="p-4 text-gray-800 sm:pr-2 sm:pl-2 rounded-lg shadow-md bg-white-300 ... ">
            <div className="flex flex-col items-center justify-center">
              <div className="w-32 h-32 overflow-hidden ">
                <img
                  src={Wellness}
                  alt="img"
                  className="object-cover object-center w-full h-full"
                />
              </div>
              <div className="pt-4">
                <h3 className="text-center font-semibold text-xl text-gray-800">
                  WellNess Products
                </h3>
              </div>
              <div className="pt-4">
                <h3 className="text-center text-gray-800 text-xs mx-4">
                  Access to living well & healing evidence & practices from
                  leading doctors & wellness practitioners
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center items-center py-16">
          <button
            type="button"
            className="bg-gradient-to-r w-56 h-12  rounded-md from-orange-400 to-orange-700 text-white"
          >
            <Link
              to={`/products`}
              className="text-gray-200 hover:text-indigo-200 text-xl"
            >
              REGISTER NOW
            </Link>{" "}
          </button>
        </div>
      </div>

      <div>
        <div className="2xl:container 2xl:mx-auto lg:py-16  md:py-12 md:py-9 ">
          <div className="flex items-center w-full lg:w-full md:w-full ">
            <img className="block  w-full " src={telomrimg1} alt="Telomr" />
          </div>
          <div className="2xl:px-20 px-6 py-20 xl:mx-auto xl:container">
            <h1 className="xl:text-4xl md:text-4xl text-3xl font-semibold leading-tight text-center text-orange-800 sm:mb-0 mb-12">
              India is expected to have around 29.8 Million patients by 2025
              with about 8 million new patients added every year. The need to
              meet the patient-centric care is ever increasing.{" "}
              <br className="md:block hidden" />
            </h1>
            {/* <div className=" relative place-items-right sm:flex items-center justify-center">
              <img
                src={Telomrimg5}
                alt="Telomrimg"
                className="w-full place-items-center xl:h-full  object-cover object-fill sm:block"
              />
            </div> */}
          </div>
          <div>
            <div className="container mx-auto">
              <div className="flex flex-col lg:flex-row justify-center items-center    ">
                <div className="lg:w-1/2 h-fit flex flex-col justify-center items-center lg:items-start h-3/6">
                  <div className="2xl:mx-auto 2xl:container  ">
                    <div className=" w-full  relative flex items-center justify-center">
                      <div className="bg-orange-600 bg-opacity-60  lg:py-4  w-full  md:px-12 px-4  items-center justify-center h-96">
                        <h1 className="text-4xl font-semibold leading-9 text-gray text-graycenter mt-12">
                          AboutUs
                        </h1>
                        <p className="mt-2 font-medium text-base text-gray-800 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:text-base lg:mx-0 italic...">
                          Telomr is a novel, health-tech platform connecting
                          innovative medicine with the right end user.
                        </p>
                        <p className="mt-2 font-medium text-base text-gray-800 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 lg:text-base md:text-xl lg:mx-0 italic... list-outside my-12">
                          There is tremendous disparity in how cutting edge
                          technologies and therapies are accessed by the common
                          man. Our platform incentivizes all parties involved,
                          to take part in the revolutionary support system for
                          public health.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 flex flex-col justify-center items-center lg:items-start py-1">
                  <div className="2xl:mx-auto 2xl:container">
                    <div className=" w-full  relative flex items-center justify-center">
                      <div className="bg-orange-700 bg-opacity-60  lg:py-4  w-full h-96  md:px-12   px-4 items-center justify-center ">
                        <h1 className="text-4xl font-semibold leading-9 text- text-graycenter mt-12">
                          WhyUs
                        </h1>
                        <p className="mt-3 text-base text-gray-800 text-small sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 lg:text-base">
                          Cancer diagnostics and Personalization is a new
                          frontier in better cancer care – however, to adopt
                          novel technologies and modernize clinical care, there
                          needs to be better engagement among the masses.
                        </p>
                        <p className="mt-3 text-base text-gray-800 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 my-12 lg:text-base">
                          Our unique business model connects the right patient
                          with the right innovation and provides a social equity
                          in accessing better healthcare
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="/services" className="mb-8 text-center">
        <h2 className="text-4xl font-bold text-black">
          Telomr's key Differentiators
        </h2>
        {/* <p className="text-lg text-gray-600">
          Here are key Telomr's Differentiators
        </p> */}
      </div>
      <div className="lg:grid lg:grid-cols-2 lg:gap-x-2 grid gap-2  pb-2 ">
      <div className="p-4 text-gray-800 hover:bg-white ... bg-white   sm:pr-2 sm:pl-2 rounded-lg shadow-md">
          <p className="mb-2 text-center text-gray-600 font-extrabold ">
            Financial assistance
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img5}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="font-bold text-green-800">
              <p className="text-center  font-medium text-gray-800">
                An aid in all monetary aspects
              </p>
            </h3>
          </div>
        </div>
       
       
        <div className="p-4 text-gray-800 bg-white sm:pr-2 sm:pl-2 rounded-lg shadow-md hover:bg-white ...">
          <p className="mb-2 text-center text-gray-600 font-extrabold  ">
            Subsidized medications
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img6}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="font-bold text-green-800">
              <p className="text-center  font-medium text-gray-800">
                Affordable & Cost effective Medication for all
              </p>
            </h3>
          </div>
        </div>
        <div className="p-4 text-gray-800 bg-white sm:pr-2 sm:pl-2 rounded-lg shadow-md hover:bg-white ...">
          <p className="mb-2 text-center text-gray-600 font-extrabold  ">
            New Technologies
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img3}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="font-bold text-green-800">
              <p className="text-center  font-medium text-gray-800">
                State-of-the-art A to Z onco technology
              </p>
            </h3>
          </div>
        </div>
        <div className="p-4 text-gray-800 sm:pr-2 sm:pl-2 rounded-lg shadow-md bg-white ... ">
          <p className="mb-2 text-center text-gray-600 font-bold font-extrabold ">
            Oncology Experts
          </p>
          <div className="flex flex-col items-center justify-center">
            <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full">
              <img
                src={img1}
                alt="img"
                className="object-cover object-center w-full h-full"
              />
            </div>
            <h3 className="text-center  font-medium text-gray-800">
              Access to National renowned experts in field of oncology.
            </h3>
          </div>
        </div>
       
      </div>
      <div>
      <div className="container mx-auto px-4 pt-8">
                <h1 className="text-5xl text-center f-m-w text-gray-700 font-bold pt-0">Latest from our Blog
</h1>
                <div className="pt-14 xl:px-0 px-4">
                    <div className="w-full lg:flex">
                        <div className="lg:w-1/2">
                        <img
            className=""
            src={blog3}
            alt=""
          />
                            <div className="mt-8 lg:mb-0 mb-8">
                                <h1 className="f-m-m text-2xl font-semibold leading-7">Breast cancer, life style practices to Prevent breast cancer</h1>
                                <p className="text-base f-m-m leading-loose mt-2">Breast Cancer is the common malignancy in females not only in World but also in India. Its frequency in India is about 32 per 100,000. The reason for rising incidence of Breast cancer is adaptation to western lifestyle i.e. late marriage, early childbirth after 35 years of age, null parity. Other risk factors are sedentary life style, high fatty fast foods, early Menarche, late Menopause, post-menopausal obesity. Apart from this about 10 percent of breast cancer have familial inheritance as apart of Hereditary Breast and ovarian cancer syndrome.</p>
                                <div className="mt-6">
                                    <a href='https://blogs.telomr.life/2022/12/29/breast-cancer-life-style-practices-to-prevent-breast-cancer/'>
                                        <p className="text-indigo-700 underline text-base font-semibold f-m-m">Read More</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/2 lg:ml-8">
                            <div className="lg:flex items-start mb-8">
                            <img
            className="h-64 w-64"
            src={blog2}
            alt=""
          />
                                <div className="lg:ml-6">
                                    <h1 className="f-m-m text-2xl font-semibold leading-7 lg:mt-0 mt-8">Anxiety in Cancer</h1>
                                    <p className="text-base f-m-m leading-loose mt-2">Cancer can change the life of an individual in an instant. And with this sudden change comes the processing and adapting to the situation. The individual naturally would respond to the situation in many different ways, anxiety being one of the most common responses among cancer patients.</p>
                                    <div className="mt-4">
                                        <a href='https://blogs.telomr.life/2022/12/29/anxiety-in-cancer/'>
                                            <p className="text-indigo-700 underline text-base font-semibold f-m-m">Read More</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:flex items-start mb-8">
                            <img
            className="h-80 w-64"
            src={blog1}
            alt=""
          />
                                <div className="lg:ml-6">
                                    <h1 className="f-m-m text-2xl font-semibold leading-7 lg:mt-0 mt-8">Sarcoma cancer</h1>
                                    <p className="text-base f-m-m leading-loose mt-2">A sarcoma is a rare type of malignant (cancerous) tumor that develops in bone and connective tissue, such as fat, muscle, blood vessels, nerves and the tissue that surrounds bones and joints. Sarcoma is the general term for a broad group of cancers that begin in the bones and in the soft (also called connective) tissues (soft tissue sarcoma).</p>
                                    <div className="mt-4">
                                        <a href='https://blogs.telomr.life/2022/12/29/sarcoma-cancer/'>
                                            <p className="text-indigo-700 underline text-base font-semibold f-m-m">Read More</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:flex items-start mb-8">
                            <img
            className="h-80 w-64"
            src={blog4}
            alt=""
          />
                                <div className="lg:ml-6">
                                    <h1 className="f-m-m text-2xl font-semibold leading-7 lg:mt-0 mt-8">Managing pain and Fatigue in cancer care</h1>
                                    <p className="text-base f-m-m leading-loose mt-2">Cancer-related fatigue is a distressing and highly prevalent symptom experienced by the majority of cancer patients both during the period of treatment and in the period after completion of the treatment. CRF not only imposes a financial burden by restraining the patient’s ability to work efficiently, but is also linked to psychological distress at significant levels.</p>
                                    <div className="mt-4">
                                        <a href='https://blogs.telomr.life/2022/12/29/managing-pain-and-fatigue-in-cancer-care/'>
                                            <p className="text-indigo-700 underline text-base font-semibold f-m-m">Read More</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center my-16 w-full">
                  <a href="https://blogs.telomr.life/blog/" className=" hover:bg-gray-200 border border-indigo-700 border-2 lg:text-2xl md:text-lg text-sm rounded f-m-m font-semibold text-indigo-700 focus:outline-none lg:px-12 px-6 lg:py-6 py-3 xl:leading-4">
                  Browse More
                  </a>
                </div>
                <div />
            </div>
      </div>
      <div className="2xl:mx-auto 2xl:container mx-4 py-16">
        <div className=" w-full  relative flex items-center justify-center">
          <img
            src={Telomrimg4}
            alt="dining"
            className="w-full h-full absolute z-0 hidden xl:block"
          />
          <img
            src={Telomrimg4}
            alt="dining"
            className="w-full h-full absolute z-0 hidden sm:block xl:hidden"
          />
          <img
            src={Telomrimg4}
            alt="dining"
            className="w-full h-full absolute z-0  sm:hidden "
          />
          <div className="bg-gray-800 bg-opacity-60 md:my-16 lg:py-16 py-10 w-full md:mx-24 md:px-12 px-4 flex flex-col items-center justify-center relative z-40">
            <h1 className="text-4xl font-semibold leading-9 text-white text-center">
              Don’t miss out!
            </h1>
            <p className="text-base leading-normal text-center text-white mt-6">
              Subscribe Us to get a Proper Cancer Care facility
              <br />
              And get new technology Updates .
            </p>
            <form
              ref={form}
              onSubmit={sendEmail}
              className=" flex justify-center w-full"
            >
              <div className="sm:border border-white flex-col sm:flex-row  flex items-center lg:w-5/12 w-full mt-12 space-y-4 sm:space-y-0">
                <input
                  className="border border-white sm:border-transparent text-base w-full font-medium leading-none text-white p-4 mx-8 focus:outline-none bg-transparent placeholder-white"
                  placeholder="Email Address"
                  required
                  name="email"
                />
                <button className="focus:outline-none focus:ring-offset-2 focus:ring border border-white sm:border-transparent w-full sm:w-auto bg-white py-4 px-4 hover:bg-opacity-75">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
