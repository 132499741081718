import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiData from "./ApiData";

export const userLogin = createAsyncThunk(
  "/login",
  async (formData, { rejectWithValue }) => {
    console.log(formData);
    try {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      // make request to backend
      const { data } = await ApiData.post("/user/login", formData, config);
      // store user's token in local storage
      localStorage.setItem("authInfo", JSON.stringify(data));
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.login_status);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
