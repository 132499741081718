import React from 'react'

const Payment = () => {
  return (
    <div>
      <div className="">
        <ul className='pt-4 list-disc'>
            <li className='pt-4 list-disc'>
            <lable>payment</lable>
        <input type="text" />
            </li>
            <li className='pt-4 list-disc'>
            <lable>payment</lable>
        <input type="text" />
            </li>
            <li className='pt-4 list-disc'>
            <lable>payment</lable>
        <input type="text" />
            </li>
            <li className='pt-4 list-disc'>
            <lable>payment</lable>
        <input type="text" className='mx-2 w-1/4'/>
            </li>
            <li className='pt-4 list-disc'>
            <lable>payment</lable>
        <input type="text" />
            </li>
        </ul>
      </div>
    </div>
  )
}

export default Payment
