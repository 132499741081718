import React, { useState } from 'react';
import { Newfooter } from '../components/Newfooter';
import NavDash from './NavDash';


function UploadForm() {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);

  const handleFile1Change = (event) => {
    setFile1(event.target.files[0]);
  };

  const handleFile2Change = (event) => {
    setFile2(event.target.files[0]);
  };

  const handleFile3Change = (event) => {
    setFile3(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Do something with the files
  };

  return (
    <div className="bg-gray-100 h-screen">
      <NavDash />
      <div className="max-w-lg mx-auto flex flex-col justify-center items-center h-full">
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="mb-4 flex flex-col justify-center items-center w-full">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="file1">
              Upload Photo
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="file1"
              type="file"
              onChange={handleFile1Change}
            />
          </div>
          <div className="mb-4 flex flex-col justify-center items-center w-full">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="file2">
              Upload Adhar Card 
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="file2"
              type="file"
              onChange={handleFile2Change}
            />
          </div>
          <div className="mb-4 flex flex-col justify-center items-center w-full">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="file3">
              Upload PAN Card
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="file3"
              type="file"
              onChange={handleFile3Change}
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <Newfooter />
    </div>
  );
}

export default UploadForm;
