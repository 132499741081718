import { useState } from "react";
import ImageOne from "../assests/tel_orange.png";
import { Link } from "react-router-dom";
import { IconButton } from "@material-tailwind/react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import AccountIcon from "../assests/account.jpg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginSharpIcon from "@mui/icons-material/LoginSharp";

// import ContactForm from "../page/ContactForm";
export default function NavBar() {
  const [navbar, setNavbar] = useState(false);

  return (
    <nav className="w-full bg-black   shadow sticky top-0 z-50  ">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-3 ">
        <div>
          <div className="flex items-center justify-between  py-2 md:py-2  space-y-8 md:flex md:space-x-6 md:space-y-0">
            <div>
              <Link to={`/`}>
                <img
                  className="w-25 h-12 rounded-full mr-4 ... rounded-full mx-auto"
                  src={ImageOne}
                  alt="Profile"
                />
              </Link>
            </div>

            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-gray-200 hover:text-indigo-200">
                <Link to={`/`} className="text-gray-200 hover:text-indigo-200">
                  Home
                </Link>{" "}
              </li>
              <li className="text-gray-200 hover:text-indigo-200">
                <Link
                  to={`/products`}
                  className="text-gray-200 hover:text-indigo-200"
                >
                  Products
                </Link>{" "}
              </li>
              <li className="text-gray-200 hover:text-indigo-200">
                <Link
                  to={`/contact_us`}
                  className="text-gray-200 hover:text-indigo-200"
                >
                  Contact us
                </Link>
              </li>
              <li className="text-gray-200 hover:text-indigo-200">
                <Link
                  to={`/about_us`}
                  className="text-gray-200 hover:text-indigo-200"
                >
                  About us
                </Link>
              </li>

              <li className="text-gray-200 hover:text-indigo-200">
                <Link
                  to={`/signin`}
                  className="text-gray-200 hover:text-indigo-200"
                >
                  Login
                </Link>
              </li>
              {/* <li className="text-gray-200 hover:text-indigo-200">
                <a href="https://telomr.life/donate.php">
                  <FavoriteIcon sx={{ color: "red" }} />
                  <span>Donate</span>
                </a>
              </li> */}
              {/* <li className="text-gray-200 hover:text-indigo-200">
                <Link
                  to={`/kyc`}
                  className="text-gray-200 hover:text-indigo-200"
                >
                  Kyc
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
