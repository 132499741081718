import React from "react";
import ImageOne from "../assests/tel_orange.png";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const Footer = () => {
  const y = new Date();
  let year = y.getFullYear();

  return (
    <footer className="bg-black dark:bg-gray-800">
      <div className="container px-10 py-8 mx-auto">
        <div className="grid gap-20 sm:grid-cols-3 lg:grid-cols-3">
          <div className="xl:ml-16">
            <div className="text-xs font-medium text-gray-400 uppercase">
              <img
                className="w-25 h-12 rounded-full  ... rounded-full mx-auto "
                src={ImageOne}
                alt="Profile"
              />
            </div>
            <Link
              to={``}
              className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-gray-400 uppercase">
                Office Address
              </b>
              : Telomr <br />
              46, 2nd floor, BEML 5th stage, Halagevaderahalli, Rajarajeshwari
              Nagara, Bengluru, 560098
            </Link>{" "}
          </div>

          <div className="pt-2 xl:ml-24">
            <div className="text-xs font-medium text-gray-400 uppercase">
              <p>Service we are providing</p>
            </div>
            <div>
              <ul className="list-disc">
                <li>
                  <Link
                    to={`/products`}
                    className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
                  >
                    Access to oncology experts.
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to={`/products`}
                    className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
                  >
                    Subsidized medication.
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to={`/products`}
                    className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
                  >
                    Access to new technologies.
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to={`/products`}
                    className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
                  >
                    Financial assistance.
                  </Link>{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className="xl:ml-16">
            <div>
              <a
                href={
                  "https://www.instagram.com/invites/contact/?i=1nd0pgor6er4w&utm_content=q1d11uv"
                }
                target="blank"
              >
                <InstagramIcon
                  className="h-8 w-8 text-white"
                  aria-hidden="true"
                />
                <span className=" font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline">
                  {" "}
                  telomr.life{" "}
                </span>
              </a>
            </div>
            <div className="pt-4">
              <a
                href={"https://www.facebook.com/profile.php?id=100087680589578"}
                target="blank"
              >
                <FacebookIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
                <span className="font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline">
                  {" "}
                  Telomr{" "}
                </span>
              </a>
            </div>
            <div className="pt-4">
              <a href={"https://www.twitter.com/telomr_life/"} target="blank">
                <TwitterIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
                <span className="font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline">
                  {" "}
                  telomr_life{" "}
                </span>
              </a>
            </div>
            <div className="pt-4">
              <a href={"https://wa.me/+919019363833"} target="blank">
                <WhatsAppIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
                <span className="font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline">
                  {" "}
                  9019363833{" "}
                </span>
              </a>
            </div>
            <div className="pt-4">
              <a href={"tel:+919019363833"} target="blank">
                <CallIcon className="h-5 w-5 text-white" aria-hidden="true" />
                <span className="font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline">
                  {" "}
                  +919019363833{" "}
                </span>
              </a>
            </div>
            <div className="pt-4">
              <a
                href={"https://www.linkedin.com/in/telomr-life-397701256/"}
                target="blank"
              >
                <LinkedInIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
                <span className="font-medium text-gray-400 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline">
                  {" "}
                  telomr life{" "}
                </span>
              </a>
            </div>
          </div>
        </div>

        <hr className="my-8 border-gray-200 dark:border-gray-700"></hr>

        <div className="sm:flex sm:items-center sm:justify-between">
          <p className="text-sm text-gray-400">
            © Copyright {year}. All Rights Reserved to Sal Biosciences
          </p>
          <p className="text-sm text-gray-400">
            <span>
              <Link to={"/privacy"} className=" text-sm text-gray-400">
                Privacy Policy
              </Link>
            </span>{" "}
            |{" "}
            <span>
              <Link to={"/term"} className=" text-sm text-gray-400">
                Terms And Condition
              </Link>
            </span>{" "}
            |{" "}
            <span>
              <Link to={"/cancelpoly"} className=" text-sm text-gray-400">
                Cancellation Policy
              </Link>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};
