import React from 'react';
import { Link } from 'react-router-dom';
const Bnpl = () => {
  return (
    <div>
       <Link to="whatsapp://send?text=Hello%20World!">
      <button>Open WhatsApp</button>
    </Link>
    </div>
  )
}

export default Bnpl
