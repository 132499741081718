import { createContext, useState, useContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const handleSetCurrentUser = (user) => {
    console.log('Setting currentUser:', user);
    setCurrentUser(user);
  }

  console.log('currentUser:', currentUser);
  console.log('currentUser:', currentUser); 
  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser:handleSetCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
  
    console.log('useAuth:', context);
  
    if (!context) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
  
    return context;
  };
