import React from 'react'

const Accout = () => {
  return (
    <div>
      This is Accout details page 
    </div>
  )
}

export default Accout
