import React, { useState } from "react";
// import { toast } from "react-toastify";
import { Footer } from "../components/Footer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ApiData from "../Api/ApiData";
import NavBar from "../components/NavBar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";

function EnrollmentForm() {
  const navigate = useNavigate();
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email_address, setEmail_address] = useState("");
  const [date_of_birth, setDate_of_birth] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [occupation, setOccupation] = useState("");
  const [salary, setSalary] = useState("");
  const [p_address, setP_address] = useState("");
  const [date_of_diagnosis, setDate_of_diagnosis] = useState("");
  const [hospital_name, setHospital_name] = useState("");
  const [cancer, setCancer] = useState("");
  const [stage_of_cancer, setStage_of_cancer] = useState("");
  const [mydocument, setMydocument] = useState("");
  const [insurance, setInsurance] = useState("");
  const [consulting_physicion, setConsulting_physicion] = useState("");
  const [reffered, setReffered] = useState("");

  const [allData, setAllData] = useState({});

  const showSuccess = (resMessage) => {
    Swal.fire({
      title: "Success",
      text: resMessage,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const showError = (resMessage) => {
    Swal.fire({
      text: resMessage,
      icon: "error",
      title: "Oops...",
      confirmButtonText: "Close",
    });
  };

  console.log(first_name);
  console.log(email_address);
  let handleSubmit = async (e) => {
    e.preventDefault();
    if (first_name === "" && email_address === "" && last_name === "" && date_of_birth === "" && phone === "" && password === "" && city === "" && region === "" && postal_code === "" && occupation === "" && salary === "" && p_address === "" && date_of_diagnosis === "" && hospital_name === "" && cancer === "" && stage_of_cancer === "" && mydocument === "" && consulting_physicion === "" ) {
      showError("Please Fill All Value !!!");
    } else {
      try {
        const res = await ApiData.post(
          `user//registration`,

          {
            first_name: first_name,
            last_name: last_name,
            email_address: email_address,
            date_of_birth: date_of_birth,
            phone: phone,
            password: password,
            city: city,
            region: region,
            postal_code: postal_code,
            occupation: occupation,
            salary: salary,
            p_address: p_address,
            date_of_diagnosis: date_of_diagnosis,
            hospital_name: hospital_name,
            cancer: cancer,
            stage_of_cancer: stage_of_cancer,
            mydocument: mydocument,
            insurance: insurance,
            consulting_physicion: consulting_physicion,
            reffered: reffered,
          },
          {
            headers: {
              "access-control-allow-origin": "*",
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );

        if (res.status === 201) {
          setAllData(res.data);
          showSuccess(res.data.message);
          navigate("/", { replace: true });
        }
      } catch (err) {
        console.log(err);
        showError(err.res.data.message);
      }
    }
  };
  console.log(allData);
  return (
    <>
      <NavBar />
      <div className="flex items-center justify-center ">
        <div className=" w-full px-12">
          <div>
            <div className="px-5 py-4 bg-gray-100 rounded-lg flex items-center justify-between mt-7">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 9.99999H20C20.2652 9.99999 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 9.99999 4 9.99999H5V8.99999C5 8.08074 5.18106 7.17049 5.53284 6.32121C5.88463 5.47193 6.40024 4.70026 7.05025 4.05025C7.70026 3.40023 8.47194 2.88462 9.32122 2.53284C10.1705 2.18105 11.0807 1.99999 12 1.99999C12.9193 1.99999 13.8295 2.18105 14.6788 2.53284C15.5281 2.88462 16.2997 3.40023 16.9497 4.05025C17.5998 4.70026 18.1154 5.47193 18.4672 6.32121C18.8189 7.17049 19 8.08074 19 8.99999V9.99999ZM17 9.99999V8.99999C17 7.67391 16.4732 6.40214 15.5355 5.46446C14.5979 4.52678 13.3261 3.99999 12 3.99999C10.6739 3.99999 9.40215 4.52678 8.46447 5.46446C7.52678 6.40214 7 7.67391 7 8.99999V9.99999H17ZM11 14V18H13V14H11Z"
                      fill="#4B5563"
                    />
                  </svg>
                </div>

                <p className="text-sm text-gray-800 pl-3">
                  We take privacy issues seriously. You can be sure that your
                  personal data is securely protected.
                </p>
              </div>
              <button className="md:block hidden focus:outline-none focus:ring-2 focus:ring-gray-700 rounded">
                <svg
                  aria-label="Close this banner"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8337 5.34166L14.6587 4.16666L10.0003 8.825L5.34199 4.16666L4.16699 5.34166L8.82533 10L4.16699 14.6583L5.34199 15.8333L10.0003 11.175L14.6587 15.8333L15.8337 14.6583L11.1753 10L15.8337 5.34166Z"
                    fill="#79808F"
                  />
                </svg>
              </button>
            </div>
            {/* forms */}

            <div>
              <div>
                <form
                  // ref={form}
                  onSubmit={handleSubmit}
                  className=" flex justify-center"
                >
                  <div className="container mx-auto">
                    <div className="flex flex-col lg:flex-row justify-center items-center py-12">
                      <div className="lg:w-1/2 flex flex-col justify-center items-center lg:items-start">
                        <div className=" w-full  sm:overflow-hidden sm:rounded-md ">
                          <div className="space-y-6  bg-orange-400 px-4 py-5 sm:p-6">
                            <h1 className="text-center text-white text-2xl my-2">
                              {" "}
                              Personal Information
                            </h1>
                            <div className="my-4 grid grid-cols-6 gap-6 ">
                              <div className="col-span-6  sm:col-span-3">
                                <label
                                  htmlFor="first_name"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  First name
                                </label>
                                <input
                                  required
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  value={first_name}
                                  onChange={(e) =>
                                    setFirst_name(e.target.value)
                                  }
                                  autoComplete="given-name"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="last_name"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Last name
                                </label>
                                <input
                                  required
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  autoComplete="last_name"
                                  value={last_name}
                                  onChange={(e) => setLast_name(e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="date_of_birth"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Date Of Birth
                                </label>
                                <input
                                  required
                                  type="date"
                                  name="date_of_birth"
                                  id="date_of_birth"
                                  autoComplete="date_of_birth"
                                  value={date_of_birth}
                                  onChange={(e) =>
                                    setDate_of_birth(e.target.value)
                                  }
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="email_address"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Email address
                                </label>
                                <input
                                  required
                                  type="email"
                                  name="email_address"
                                  id="email_address"
                                  autoComplete="email"
                                  placeholder="xyz@gmail.com"
                                  value={email_address}
                                  onChange={(e) =>
                                    setEmail_address(e.target.value)
                                  }
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="phone"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Phone Number
                                </label>
                                <input
                                  required
                                  id="phone"
                                  name="phone"
                                  type="phone"
                                  autoComplete="phone"
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="password"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Password
                                </label>
                                <input
                                  required
                                  id="password"
                                  name="password"
                                  type="password"
                                  autoComplete="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="city"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  City
                                </label>
                                <input
                                  type="text"
                                  required
                                  name="city"
                                  id="city"
                                  autoComplete="address-level2"
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="region"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  State / Province
                                </label>
                                <input
                                  type="text"
                                  required
                                  name="region"
                                  id="region"
                                  autoComplete="address-level1"
                                  value={region}
                                  onChange={(e) => setRegion(e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="postal_code"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  ZIP / Postal code
                                </label>
                                <input
                                  type="text"
                                  required
                                  name="postal_code"
                                  id="postal_code"
                                  autoComplete="postal_code"
                                  value={postal_code}
                                  onChange={(e) =>
                                    setPostal_code(e.target.value)
                                  }
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="occupation"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Occupation
                                </label>
                                <input
                                  type="text"
                                  name="occupation"
                                  required
                                  id="occupation"
                                  value={occupation}
                                  onChange={(e) =>
                                    setOccupation(e.target.value)
                                  }
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  placeholder="Type of occupation"
                                />
                              </div>
                              <div className="col-span-6 ">
                                <label
                                  htmlFor="salary"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Overall Consolidated Salary
                                </label>
                                <input
                                  type="text"
                                  name="salary"
                                  required
                                  id="salary"
                                  value={salary}
                                  onChange={(e) => setSalary(e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  placeholder="Overall Consolidated Salary"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="p_address"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Address{" "}
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="p_address"
                                  name="p_address"
                                  required
                                  rows={3}
                                  value={p_address}
                                  onChange={(e) => setP_address(e.target.value)}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  placeholder="in 200 words or less."
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-5 md:col-span-2 md:mt-0 px-56 bg-orange-500">
                        <div className="  sm:overflow-hidden sm:rounded-md"> */}
                      <div className="lg:w-1/2 flex flex-col justify-center items-center         lg:items-start ">
                        <div className="w-full  sm:overflow-hidden sm:rounded-md ">
                          <div className="space-y-6  bg-white-500  px-4 py-5 sm:p-6">
                            <h1 className="text-center text-black text-2xl">
                              {" "}
                              Additional Information
                            </h1>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="date_of_dignosis"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Date Of Diagnosis
                              </label>
                              <input
                                required
                                type="date"
                                name="date_of_diagnosis"
                                id="date_of_diagnosis"
                                autoComplete="date_of_diagnosis"
                                value={date_of_diagnosis}
                                onChange={(e) =>
                                  setDate_of_diagnosis(e.target.value)
                                }
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 ">
                              <label
                                htmlFor="hospital_name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Hospital Name
                              </label>

                              <input
                                type="text"
                                required
                                name="hospital_name"
                                id="hospital_name"
                                value={hospital_name}
                                onChange={(e) =>
                                  setHospital_name(e.target.value)
                                }
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="hospital_name"
                              />
                            </div>
                            <div className="col-span-6 ">
                              <label
                                htmlFor="cancer"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Type of Cancer
                              </label>

                              <input
                                type="text"
                                required
                                name="cancer"
                                id="cancer"
                                value={cancer}
                                onChange={(e) => setCancer(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Type of cancer"
                              />
                            </div>
                            {/* <div className="col-span-6 ">
                                  <label className="block text-sm font-medium text-gray-700" for="grid-state">
                                    Stage of Cancer
                                  </label>
                                  <div class="relative">
                                    <select className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" id="stage_of_cancer" name="stage_of_cancer" required>
                                      <option>First stage</option>
                                      <option>Second stage</option>
                                      <option>Third stage</option>
                                      <option>Fourth stage</option>
                                    </select>
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                  </div>
                                  </div> */}
                            <div className="col-span-6">
                              <label
                                htmlFor="stage_of_cancer"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Stage of Cancer
                              </label>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="stage_of_cancer"
                                id="stage_of_cancer"
                                value={stage_of_cancer}
                                onChange={(e) =>
                                  setStage_of_cancer(e.target.value)
                                }
                                required
                              >
                                <FormControlLabel
                                  value="first"
                                  control={<Radio />}
                                  label="I"
                                />
                                <FormControlLabel
                                  value="second"
                                  control={<Radio />}
                                  label="II"
                                />
                                <FormControlLabel
                                  value="third"
                                  control={<Radio />}
                                  label="III"
                                />
                                <FormControlLabel
                                  value="fourth"
                                  control={<Radio />}
                                  label="IV"
                                />
                              </RadioGroup>
                            </div>
                            <div className="col-span-6 ">
                              <label
                                htmlFor="mydocument-link"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Report Link
                              </label>

                              <input
                                required
                                type="text"
                                name="mydocument"
                                id="mydocument"
                                value={mydocument}
                                onChange={(e) => setMydocument(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="https://drive.google.com/mydocumentlink"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="insurance"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Medical Insurance(If any)
                              </label>
                              <input
                                type="text"
                                name="insurance"
                                id="insurance"
                                autoComplete="insurance"
                                placeholder="Medical Insurance Number"
                                value={insurance}
                                onChange={(e) => setInsurance(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="consulting_physicion"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Consulting Physician
                              </label>
                              <input
                                required
                                type="text"
                                name="consulting_physicion"
                                id="consulting_physicion"
                                autoComplete="consulting_physicion"
                                placeholder="Consulting Physicion Name"
                                value={consulting_physicion}
                                onChange={(e) =>
                                  setConsulting_physicion(e.target.value)
                                }
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="reffered"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Referred By
                              </label>
                              <input
                                type="text"
                                name="reffered"
                                id="reffered"
                                autoComplete="reffered"
                                placeholder="reffered"
                                value={reffered}
                                onChange={(e) => setReffered(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div>
                      </div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={handleSubmit}
        type="submit"
        className="inline-flex text-xl justify-center item-center rounded-md border border-transparent bg-gray-600 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 ease-in-out"
      >
        Submit
      </button>
      <Footer />
    </>
  );
}
export default EnrollmentForm;


