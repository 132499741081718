import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ApiData from "../Api/ApiData";
import NavBar from "../components/NavBar";
import Swal from "sweetalert2";

import FormGroup from "@mui/material/FormGroup";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { Checkbox } from "@material-tailwind/react";
const Products = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState();
  const [open2, setOpen2] = useState();
  const [open3, setOpen3] = useState();

  const handleOpen = () => setOpen(!open);
  const handleOpen1 = () => setOpen1(!open1);
  const handleOpen2 = () => setOpen2(!open2);
  const handleOpen3 = () => setOpen3(!open3);

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email_address, setEmail_address] = useState("");
  const [date_of_birth, setDate_of_birth] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState("");

  const [postal_code, setPostal_code] = useState("");

  const [p_address, setP_address] = useState("");

  const [myplan, setMyplan] = useState("");

  const [reffered, setReffered] = useState("");
  const [amount, setAmount] = useState("");

  const [allData, setAllData] = useState({});

  const showSuccess = (resMessage) => {
    Swal.fire({
      title: "Success",
      text: resMessage,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const showError = (resMessage) => {
    Swal.fire({
      text: resMessage,
      icon: "error",
      title: "Oops...",
      confirmButtonText: "Close",
    });
  };

  console.log(first_name);
  console.log(email_address);

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (
      first_name === "" &&
      email_address === "" &&
      last_name === "" &&
      date_of_birth === "" &&
      phone === "" &&
      password === "" &&
      city === "" &&
      postal_code === "" &&
      amount === "" &&
      p_address === "" &&
      myplan === "" &&
      reffered === ""
    ) {
      showError("Please Fill All Value !!!");
    } else {
      try {
        const res = await ApiData.post(
          `/registration`,

          {
            first_name: first_name,
            last_name: last_name,
            email_address: email_address,
            date_of_birth: date_of_birth,
            phone: phone,
            password: password,
            city: city,

            postal_code: postal_code,

            p_address: p_address,

            myplan: myplan,
            amount: amount,

            reffered: reffered,
          },
          {
            headers: {
              "access-control-allow-origin": "*",
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );

        if (res.status === 201) {
          setAllData(res.data);

          showSuccess("OTP sent successfully");

          window.location.href = "https://telomr.life/dataFrom.php";
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          // if the error response has a message property, display it to the user
          showError(err.response.data.message);
        } else {
          // if the error response does not have a message property, display a generic error message
          showError("An error occurred. Please try again later.");
        }
      }
    }
  };

  console.log(allData);

  return (
    <>
      <div>
        <NavBar />
        <div>
          <p className="text-center pt-8 capitalize underline text-orange-600 text-2xl font-bold ">
            About OncoProtect
          </p>
          <p className="py-12 mx-12 text-left font-thin text-gray-600 text-xl leading-16">
            OncoProtect provides numerous benefits to the subscriber.
            OncoProtect helps reduce the burden of costs incurred for
            chemotherapy, medications and medical procedures in a reimbursement
            model. OncoProtect subscriber can also avail access to high quality
            healthcare and novel technologies at a significant reduction in cost
            of access.
          </p>
          <p className="py-2 mx-24 text-xl text-center">
            To enroll, just subscribe to one of the product and avail immediate
            coverage for the first year of cancer management journey.{" "}
            <p className="text-blue-800 font-medium ">
              <Link to={"/term"}>(Terms and conditions apply)</Link>
            </p>
          </p>
        </div>
        <div className=" py-8 w-full items-center justify-items-center lg:grid ">
          <div className="bg-orange-50 rounded-lg shadow-xl border-2  border-solid lg:w-2/3">
            <div className="mx-8 my-8">
              <div className="grid gap-y-4">
                <div className="bg-orange-100 rounded-lg shadow-xl min-h-[80px] opacity-80">
                  <div className="bg-orange-800 w-1/4">
                    <p className="text-center mx-2 text-white  font-bold leading-8">
                      Onco-Protect Plan
                    </p>
                  </div>
                  <p>
                    <span className="text-left mx-4 text-2xl">
                      Easy appointment booking and more discounts
                    </span>{" "}
                    <br />
                    <span className="mx-4">
                      {" "}
                      Join now and enjoy all the benefits
                    </span>
                    <ul className="my-4">
                      <span className="mx-8 text-xl font-bold">
                        Membership includes
                      </span>{" "}
                      <br />
                      <p className="my-2 mx-8">
                        TELOMR, offers a very seamless digital experience,
                        through
                      </p>
                      <li className="list-disc mx-14">
                        {" "}
                        <span className="font-bold">
                          Buy now pay later (BNPL)
                        </span>{" "}
                        - Access services now, pay later, in affordable payment
                        schemes and timelines. To provide cost efficient and
                        quality health services to un-served and under-served
                        areas at affordable prices.
                      </li>
                      <li className="list-disc mx-14">
                        {" "}
                        <span className="font-bold">Cashback services</span> -
                        Get discounted cashback (meaning, actual cash, not
                        credit or redeemable points) - for select diagnostics
                        and drugs.
                      </li>
                      <li className="list-disc mx-14">
                        <span className="font-bold">Booking Appointments</span>{" "}
                        - Consultations to Oncology specialist made easy and
                        accessible to patients. Intended to overcome
                        geographical barriers, connecting users who are not in
                        the same physical location.
                      </li>
                      <li className="list-disc mx-14">
                        {" "}
                        <span className="font-bold">Wellness Products</span> -
                        Free/subsidized yoga/wellness sessions and counselling
                        services (Availability basis).
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="bg-white-500 rounded-lg shadow-xl min-h-[50px] border-2  list-disc">
                  <p className="font-bold text-2xl my-4 mx-4">
                    Choose a plan that's right for you
                  </p>

                  <p>
                    <div className="">
                      <label
                        htmlFor="stage_of_cancer"
                        className="block text-sm font-medium text-gray-700"
                      ></label>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="stage_of_cancer"
                        id="stage_of_cancer"
                        value={myplan}
                        onChange={(e) => setMyplan(e.target.value)}
                        required
                      >
                        <div class="lg:grid grid-cols-3 gap-12 my-7 mx-16 ">
                          <div class="bg-gray-100 h-32 mt-5">
                            {" "}
                            <Fragment>
                              <Button
                                onClick={handleOpen1}
                                className="h-12 w-4"
                              >
                                <FormControlLabel
                                  value="6 Months plan"
                                  control={<Radio />}
                                />
                              </Button>
                              <Dialog
                                className="lg:w-2/3 "
                                open={open1}
                                handler={handleOpen1}
                                animate={{
                                  mount: { scale: 1, y: 0 },
                                  unmount: { scale: 0.9, y: -100 },
                                }}
                              >
                                <DialogHeader>Enrollment</DialogHeader>
                                <DialogBody divider>
                                  <div className="">
                                    <label
                                      htmlFor="amount"
                                      className="block text-sm font-medium text-gray-700"
                                    ></label>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="amount"
                                      id="amount"
                                      value={amount}
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                      required
                                    >
                                      <div class="lg:grid grid-cols-2 gap-12 my-4 mx-8">
                                        <div class="bg-gray-100 h-32 mt-5">
                                          {" "}
                                          <FormControlLabel
                                            value="1425"
                                            control={<Radio />}
                                            label={<b>Monthly payment</b>}
                                          />
                                          <div></div>
                                          <p className=""></p>A one time payment
                                          for the first three months should be
                                          paid, we will convert it into a
                                          monthly billing automatically at the
                                          end of the cycle
                                        </div>
                                        <div class="bg-gray-100 h-32 mt-5">
                                          {" "}
                                          <FormControlLabel
                                            value="2565"
                                            control={<Radio />}
                                            label={<b>Annual Payment</b>}
                                          />
                                          <div></div>
                                          <p>
                                            One time annual payment ensure
                                            seamless services without being
                                            hassled by multiple invoices. You
                                            will also benefit from a 10%
                                            discount!
                                          </p>
                                        </div>
                                      </div>
                                    </RadioGroup>
                                  </div>
                                </DialogBody>
                                <DialogFooter>
                                  <Button
                                    variant="gradient"
                                    color="green"
                                    onClick={(e) => {
                                      handleOpen1(e);
                                    }}
                                    className="bg-gradient-to-r w-28 h-12  rounded-md from-orange-500 to-orange-300 "
                                  >
                                    <span>Select</span>
                                  </Button>
                                </DialogFooter>
                              </Dialog>
                            </Fragment>
                            <div></div>
                            <p className="text-2xl mx-8 font-bold">
                              6 Months Plan <br />
                              ₹475/month
                            </p>
                          </div>
                          <div className="my-4">
                            <div class="bg-gray-100 h-32 ">
                              <Fragment>
                                <Button
                                  onClick={handleOpen2}
                                  className="h-12 w-4"
                                  default
                                >
                                  <FormControlLabel
                                    value="12 Months plan"
                                    control={<Radio />}
                                  />
                                </Button>
                                <Dialog
                                  className="lg:w-2/3 "
                                  open={open2}
                                  handler={handleOpen2}
                                  animate={{
                                    mount: { scale: 1, y: 0 },
                                    unmount: { scale: 0.9, y: -100 },
                                  }}
                                >
                                  <DialogHeader>Enrollment</DialogHeader>
                                  <DialogBody divider>
                                    <div className="">
                                      <label
                                        htmlFor="amount"
                                        className="block text-sm font-medium text-gray-700"
                                      ></label>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="amount"
                                        id="amount"
                                        value={amount}
                                        onChange={(e) =>
                                          setAmount(e.target.value)
                                        }
                                        required
                                      >
                                        <div class="lg:grid grid-cols-2 gap-12 my-4 mx-8">
                                          <div class="bg-gray-100 h-32 mt-5">
                                            {" "}
                                            <FormControlLabel
                                              value="1275"
                                              control={<Radio />}
                                              label={<b>Monthly payment</b>}
                                            />
                                            <div></div>
                                            <p className="">
                                              A one time payment for the first
                                              three months should be paid, we
                                              will convert it into a monthly
                                              billing automatically at the end
                                              of the cycle
                                            </p>
                                          </div>
                                          <div class="bg-gray-100 h-32 mt-5">
                                            {" "}
                                            <FormControlLabel
                                              value="4590"
                                              control={<Radio />}
                                              label={<b>Annual Payment</b>}
                                            />
                                            <div></div>
                                            <p>
                                              {" "}
                                              One time annual payment ensure
                                              seamless services without being
                                              hassled by multiple invoices. You
                                              will also benefit from a 10%
                                              discount!
                                            </p>
                                          </div>
                                        </div>
                                      </RadioGroup>
                                    </div>
                                  </DialogBody>
                                  <DialogFooter>
                                    <Button
                                      variant="gradient"
                                      color="green"
                                      onClick={(e) => {
                                        handleOpen2(e);
                                      }}
                                      className="bg-gradient-to-r w-28 h-12  rounded-md from-orange-500 to-orange-300 "
                                    >
                                      <span>Select</span>
                                    </Button>
                                  </DialogFooter>
                                </Dialog>
                              </Fragment>
                              <p className="text-2xl mx-8 font-bold">
                                12 Months Plan <br />
                                ₹425/month
                              </p>
                            </div>
                          </div>
                          <div class="bg-gray-100 h-32 mt-5 ">
                            <Fragment>
                              <Button
                                onClick={handleOpen3}
                                className="h-12 w-4"
                              >
                                <FormControlLabel
                                  value="Lifetime plan"
                                  defaultChecked
                                  control={<Radio />}
                                />
                              </Button>
                              <Dialog
                                className="lg:w-2/3 "
                                open={open3}
                                handler={handleOpen3}
                                animate={{
                                  mount: { scale: 1, y: 0 },
                                  unmount: { scale: 0.9, y: -100 },
                                }}
                              >
                                <DialogHeader>Payment Options</DialogHeader>
                                <DialogBody divider>
                                  <div className="">
                                    <label
                                      htmlFor="amount"
                                      className="block text-xl font-medium text-gray-700"
                                    ></label>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="amount"
                                      id="amount"
                                      value={amount}
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                      required
                                    >
                                      <div class="lg:grid grid-cols-2 gap-9 my-5 mx-8">
                                        <div class="bg-gray-100  h-32 mt-5">
                                          {" "}
                                          <FormControlLabel
                                            value="6750"
                                            control={<Radio />}
                                            label={<b>One Time payment</b>}
                                          />
                                          <div></div>
                                          <p className="">
                                            A one time payment of 18 month
                                            subscription will be billed. This
                                            will cover the entire lifetime of
                                            your usage
                                          </p>
                                        </div>
                                      </div>
                                    </RadioGroup>
                                  </div>
                                </DialogBody>
                                <DialogFooter>
                                  <Button
                                    variant="gradient"
                                    color="green"
                                    onClick={(e) => {
                                      handleOpen3(e);
                                    }}
                                    className="bg-gradient-to-r w-28 h-12  rounded-md from-orange-500 to-orange-300 "
                                  >
                                    <span>Save</span>
                                  </Button>
                                </DialogFooter>
                              </Dialog>
                            </Fragment>
                            <p className="text-2xl mx-10 font-bold">
                              Lifetime plan <br />
                              ₹375/month
                            </p>
                          </div>
                        </div>
                      </RadioGroup>
                    </div>
                  </p>
                </div>
              </div>
              <div className="flex space-x-2 justify-center pt-5">
                <Fragment>
                  <Button
                    onClick={handleOpen}
                    className="bg-orange-400 justify-center"
                  >
                    Enroll
                  </Button>
                  <Dialog
                    className="lg:w-2/3 md:w-1/3 "
                    open={open}
                    handler={handleOpen}
                    animate={{
                      mount: { scale: 1, y: 0 },
                      unmount: { scale: 0.9, y: -100 },
                    }}
                  >
                    <DialogHeader>Enrollment</DialogHeader>
                    <DialogBody divider>
                      <form className=" flex justify-center">
                        <div className="space-y-6  bg-orange-400 px-4 py-8 sm:p-8 ">
                          <div className="my-4 lg:grid grid-cols-6 gap-6 ">
                            <div className="">
                              <label
                                htmlFor="first_name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                First name
                              </label>
                              <input
                                required
                                type="text"
                                name="first_name"
                                id="first_name"
                                value={first_name}
                                onChange={(e) => setFirst_name(e.target.value)}
                                autoComplete="given-name"
                                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="last_name"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Last name
                              </label>
                              <input
                                required
                                type="text"
                                name="last_name"
                                id="last_name"
                                autoComplete="last_name"
                                value={last_name}
                                onChange={(e) => setLast_name(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="date_of_birth"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Date Of Birth
                              </label>
                              <input
                                required
                                type="date"
                                name="date_of_birth"
                                id="date_of_birth"
                                autoComplete="date_of_birth"
                                value={date_of_birth}
                                onChange={(e) =>
                                  setDate_of_birth(e.target.value)
                                }
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="email_address"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email address
                              </label>
                              <input
                                required
                                type="email"
                                name="email_address"
                                id="email_address"
                                autoComplete="email"
                                placeholder="xyz@gmail.com"
                                value={email_address}
                                onChange={(e) =>
                                  setEmail_address(e.target.value)
                                }
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="phone"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Phone Number
                              </label>
                              <input
                                required
                                id="phone"
                                name="phone"
                                type="phone"
                                autoComplete="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              />
                              {/* <PhoneInput
      placeholder="Enter phone number"
      
      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm block text-sm font-medium text-gray-700"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}/> */}
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="phone"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Postal code
                              </label>
                              <input
                                required
                                id="postal_code"
                                name="postal_code"
                                type="postal_code"
                                autoComplete="postal_code"
                                value={postal_code}
                                onChange={(e) => setPostal_code(e.target.value)}
                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="phone"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Address
                              </label>
                              <input
                                required
                                id="p_address"
                                name="p_address"
                                type="p_address"
                                autoComplete="p_address"
                                value={p_address}
                                onChange={(e) => setP_address(e.target.value)}
                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="city"
                                className="block text-sm font-medium text-gray-700"
                              >
                                City
                              </label>
                              <input
                                id="city"
                                name="city"
                                type="city"
                                autoComplete="city"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="reffered"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Referred(Optional)
                              </label>
                              <input
                                id="reffered"
                                name="reffered"
                                type="reffered"
                                autoComplete="reffered"
                                value={reffered}
                                onChange={(e) => setReffered(e.target.value)}
                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Create New Password
                              </label>
                              <input
                                required
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <FormGroup className="mx-4">
                            <FormControlLabel
                              requird
                              control={<Checkbox defaultChecked />}
                              label={
                                <div>
                                  <span>
                                    I accept and acknowledge the consent form{" "}
                                  </span>
                                  <Link
                                    to={"/consern"}
                                    className=" text text-blue-400"
                                  >
                                    (Click here)
                                  </Link>
                                </div>
                              }
                            />
                            <FormControlLabel
                              required
                              control={<Checkbox defaultChecked />}
                              label={
                                <span>
                                  I accept the terms and conditions and privacy
                                  policy
                                  <Link
                                    to={"/privacy"}
                                    className=" text text-blue-400"
                                  >
                                    (Click here)
                                  </Link>
                                </span>
                              }
                            />
                          </FormGroup>
                        </div>
                      </form>
                    </DialogBody>
                    <DialogFooter>
                      <Button
                        variant="text"
                        color="orange-500"
                        onClick={handleOpen}
                        className="bg-gradient-to-r w-26 h-12  rounded-md from-red-500 to-red-500   px-4..."
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        variant="gradient"
                        color="green"
                        onClick={(e) => {
                          handleSubmit(e);

                          handleOpen(e);
                        }}
                        className="bg-gradient-to-r w-26 h-12  rounded-md from-green-500 to-green-500 "
                      >
                        <span>Save</span>
                      </Button>
                    </DialogFooter>
                  </Dialog>
                </Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Products;
