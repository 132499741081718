import React from "react";
import { Footer } from "../components/Footer";
import NavBar from "../components/NavBar";

export const Cancelpoly = () => {
  return (
    <div className="bg-orange-300 bg-opacity-60">
      <NavBar />
      <div className="2xl:mx-auto 2xl:container ">
        <div className=" w-full  relative flex items-center justify-center">
          <div className="py-10 w-full md:mx-24 md:px-12 px-4 flex flex-col items-center justify-center relative z-40">
            <h1 className="text-4xl font-semibold leading-9 text-gray text-center">
              CANCELLATION AND REFUND POLICY
            </h1>
            <p className="text-xl text-base leading-normal text-left text-gray mt-6">
              <h1 className="text-xl font-semibold leading-9 text-gray  pt-4">
                1.General:
              </h1>
              <ul className="list-disc pl-16">
                <li>
                  www.telomr.life (website) is operated by Sal Biosciences Pvt
                  Ltd (We/Our/Us)
                </li>
                <li>
                  We are committed in providing our users/customer with highest
                  quality services in Cancer Care. However, on rare occasions,
                  services may be found to be deficient. In such cases, we offer
                  refund in accordance with this Cancellation and Refund Policy
                  (“Policy”)
                </li>
                <li>
                  You are advised to read our Terms and Conditions along with
                  this Policy.
                </li>
                <li>
                  By using this website, you agree to be bound by the terms
                  contained in this Policy without Modification. If you do not
                  agree to the terms contained in this policy, You are advised
                  not to transact on this website.
                </li>
                <li>
                  Please read this Policy before availing services on this
                  Website, so that you understand your rights as well as what
                  You can expect from Us if You are not happy with Your
                  Purchase.
                </li>
                <li>
                  This Policy and regulations are in compliance with The
                  Consumer Protection Act 2019 along with The Consumer
                  Protection (e-commerce) Rules 2020.
                </li>
              </ul>
              <h1 className="text-xl font-semibold leading-9 text-gray  pt-4">
                2.Definitions
              </h1>
              <ul className="list-disc pl-16">
                <li>
                  Business Days- means a day that is not Saturday, Sunday,
                  public or national holiday or bank holiday in India or in the
                  state where our office is located.
                </li>
                <li>
                  Customer/ User: Means a person who avails services for
                  consideration and does not include commercial purchases.
                </li>
                <li>
                  Date of Transaction: Means the date of invoice of the
                  services, which includes the date of renewal processed in
                  accordance with the terms and conditions of the applicable
                  service agreement.
                </li>
                <li>Website: means this website with URL: www.telomr.life</li>
              </ul>
              <h1 className="text-xl font-semibold leading-9 text-gray  pt-4">
                3.Cancellation Policy and Procedures
              </h1>
              <ul className="pl-8">
                <li>
                  <span className="text-xl underline">
                    Q . How can I cancel an order?
                  </span>{" "}
                  <br />
                  <span className="text-xl underline">A:-</span> &nbsp;You can
                  raise a Cancel request within the time frame mentioned below
                  by following these simple steps on the Platform: <br/>1.Go to My
                  Orders. <br/>2.Select the respective order and click on
                  ‘Cancellation’. <br/>3.Select a reason from drop down <br/>4.Fill the form
                  with requested information <br/>5.Submit <br/>After the submission of
                  cancellation request, we will check the veracity of request
                  and revert within 48 hours from time the cancellation request
                  is submitted.
                </li>
                <li>
                  <span className="text-xl underline">
                    Q . When can I cancel an order?
                  </span>{" "}
                  <br />
                  <span className="text-xl underline">A:-</span> &nbsp;We have
                  product specific cancellation policy. The Cancellation window
                  ranges from 48 hours to 30 days from the date of purchase of
                  subscription plan. We will process 100 % refund, only if the
                  User submits cancellation request with 48 hours from the date
                  of purchase of subscription plan. Any cancellation request
                  received after 48 hours to 30 days; the refund amount will be
                  subject to cancellations charges applicable at the time of
                  processing the request. The charges may be related to partial
                  services provided by Us.
                </li>
                <li>
                  <span className="text-xl underline">
                    Which Products/service are not eligible for cancellation?
                  </span>
                  <br />
                  <span className="text-xl underline">A:-</span> &nbsp; We will
                  not process any cancellation request raised on or after 30
                  days from the date of purchase of subscription plan. We will
                  not process any cancellation request, if any claims are
                  pending or outstanding dues.
                </li>
                <li>
                  <span className="text-xl underline">
                    Q. Standing Instructions or e-mandate for recurring
                    transactions ?
                  </span>
                  <br />
                  <span className="text-xl underline">A:-</span> &nbsp; If the
                  User has opted to for e-mandate for recurring transactions to
                  make payment for his subscription fee, intends to cancel the
                  service, shall intimate his bank and cancel the e-mandate
                  request from user end. Any Standing Instruction given by the
                  User with respect to the Account shall be subject to
                  acceptance by the Bank. Notwithstanding any acceptance and or
                  execution by the Bank, such instruction shall be at full risk
                  and responsibility of the User. For new standing instructions,
                  User will need to do a registration process and re-enter the
                  validity period and maximum amount of the standing
                  instruction.
                </li>
              </ul>
              <h1 className="text-xl font-semibold leading-9 text-gray  pt-4">
                4.Refund Policy
              </h1>
              <span className="text-xl underline">
                Q. When can I expect the amount to be refunded?
              </span>
              <br />
              Please note that the refund amount mentioned at the time of
              cancellation on the Platform is an estimate and will be finalized
              after completion of verification. The refund will be processed as
              per method of payment within stipulated Timeline.
              <table className="table-auto border border-black">
                <tbody>
                  <tr>
                    <td className="border border-black ">
                      <span className="mx-4">NEFT</span>
                    </td>
                    <td className="border border-black ">
                      <span className="mx-4">1-3 business days</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black ">
                      <span className="mx-4">Online refund</span>
                    </td>
                    <td className="border border-black">
                      <span className="mx-4">
                        7-10 business days subject to the bank turnaround time
                        and RBI Guidelines.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black">
                      <span className="mx-4">Other Wallets</span>
                    </td>
                    <td className="border border-black">
                      <span className="mx-4">2-3 business days</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h1 className="text-xl font-semibold leading-9 text-gray  pt-4">
                5.Refusal to cancellation /refund request
              </h1>
              We reserve the right to refuse any request for refund if such
              request is not in compliance with policy or applicable laws.
              <h1 className="text-xl font-semibold leading-9 text-gray  pt-4">
                6.Data Privacy
              </h1>
              The privacy of your data supplied to us during the cancellation
              and refund procedure is also governed by our privacy policy.
              <h1 className="text-xl font-semibold leading-9 text-gray  pt-4">
                7.Grievance Redressal Process
              </h1>
              We strive to provide the best customer experience from our end,
              however, if you face any issues and concerns with our services, we
              request you to refer to this policy which provides the detailed
              steps for customer grievance redressal. The Company’s Customer
              Support Policy has been formulated in line with regulatory
              guidelines and other applicable laws on Customer Service. The
              policy outlines the framework for addressing customer grievances.
              <ul className="list-disc pl-16">
                <li>
                  Contact Customer care no &nbsp;
                  <span className="underline">+919019363833</span>&nbsp; with
                  any issue /complaints, if not resolved within 7 to 10 working
                  days.
                </li>
                <li>
                  Write an email to Grievance Officer with detailed information
                  about the issue/ complaint. Our Officer in charge will check
                  the authenticity of complaint and acknowledge the receipt
                  within 48 hours. Resolution/ redressal will be provided with
                  30 days from date of receipt of acknowledgment.
                </li>
              </ul>
              <ul className="list-disc pl-16 text-xl">
                <li>Name:Sharath Vasista</li>
                <li>
                  Address: 46, 2nd Floor, BEML 5th Stage, Halagevaderahalli
                  Rajarajeshwari Nagar,{" "}
                </li>
                <li>Bengaluru, 560098 </li>
                <li>Phone number:9886626798</li>
                <li>E-mail: connect@telomr.life</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
