import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageOne from "../assests/tel_orange.png";

export default function Headers() {
  const [first_name, setFirst_name] = useState("");
  const [user_id, setUser_id] = useState("");
  useEffect(() => {
    const storedFirst_name = localStorage.getItem("first_name");
    if (storedFirst_name) {
      setFirst_name(storedFirst_name);
    }
    const storedUser_id = localStorage.getItem("user_id");
    if (storedUser_id) {
      setUser_id(storedUser_id);
    }
  }, []);
  return (
    <>
       <nav className="relative flex flex-wrap items-center justify-between  py-3 bg-black   shadow sticky top-0 z-50 ">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li>
              <Link to={``}>
                <img
                  className="w-25 h-12 rounded-full mr-4 ... rounded-full mx-auto"
                  src={ImageOne}
                  alt="Profile"
                />
              </Link>
            </li>
            <li className="px-3 py-2 flex items-center text- uppercase   text-white hover:opacity-75">
              <h1>Welcome:-{first_name}</h1>
              
            </li>
          </ul>
            
          
          
        </div>

        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            
            <li className="px-3 py-2 flex items-center text- uppercase  text-white hover:opacity-75">
              <span>Contact :- +919019363833</span>
            </li>
            <li className="px-3 py-2 flex items-center text- uppercase   text-white hover:opacity-75">
              <span>
                Email:-{" "}
                <span className="text- lowercase ">connect@telomr.life</span>
              </span>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
