import React, { Fragment, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//contextProvider
import NavBar from "./components/NavBar";
import LandingPage from "./components/LandingPage";
import About from "./page/About";
import Products from "./container/Products";


import Signup from "./auth/Signup";
import Forgot from "./auth/Forgot";
import { Term } from "./auth/Term";
import Privacy from "./auth/Privacy";
import Joinform from "./Joining/Joinform";
import { Cancelpoly } from "./auth/Cancelpoly";
import { Disclouser } from "./auth/Disclouser";
import Sidebar from "./Dashboard/Dashboard";
import Headers from "./components/Header";
import { Newfooter } from "./components/Newfooter";
import Signin from "./auth/otplogin";
import Profile from "./Dashboard/Profile";
import Cancle from "./Dashboard/Cancel";
import NavDash from "./Dashboard/NavDash";
import Editprof from "./Dashboard/Editprof";
import Side from "./Dashboard/payment/Side";
import Accout from "./Dashboard/payment/Accout";
//import OtpLogin from "./auth/otplogin";
import Payment from "./Dashboard/payment";
import { ContactForm } from "./page/ContactForm";
import {Consern} from "./auth/Consern";
import Dashheader from "./components/Dashheader";
import Personalinfo from "./Dashboard/Personalinfo";
import UserDetailsForm from "./Dashboard/test";
import Kyc from "./Dashboard/Kyc";
import PatientsDetailsForm from "./Dashboard/Patient";
import Appointment from "./Dashboard/Appointment";
import Bnpl from "./Dashboard/Bnpl";

const App = (props) => {
  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<LandingPage />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/About_us" element={<About />} />
        <Route path="/nav" element={<NavBar />} />
        <Route path="/head" element={<Headers />} />
        <Route path="/foot" element={<Newfooter />} />
        <Route path="/contact_us" element={<ContactForm />} />
        <Route path="/products" element={<Products />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/term" element={<Term />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/joinus" element={<Joinform />} />
        <Route path="/cancelpoly" element={<Cancelpoly />} />
        <Route path="/disclouser" element={<Disclouser />} />
        <Route path="/dashboard" element={<Sidebar />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/cancle" element={<Cancle />} />
        <Route path="/navdash" element={<NavDash />} />
        <Route path="/edit" element={<Editprof />} />
        <Route path="/side" element={<Side />} />
        <Route path="/accountdetails" element={<Accout />} />
        <Route path="/payment" element={<Payment />}/>
        <Route path="/consern" element={<Consern />}/>
        <Route path="/dashheader" element={<Dashheader />}/>
        <Route path="/prof" element={<Personalinfo />}/>
        <Route path="/prof2" element={<UserDetailsForm />}/>
        <Route path="/kyc" element={<Kyc />}/>
        <Route path="/patients" element={<PatientsDetailsForm />}/>
        <Route path="/appointment" element={<Appointment />}/>
        <Route path="/bnpl" element={<Bnpl />}/>
      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={3002}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default App;
