import React, { useState } from "react";
import { Footer } from "../components/Footer";
import NavBar from "../components/NavBar";
import { AuthProvider } from '../Api/AuthContext';
import Swal from "sweetalert2";
import ApiData from "../Api/ApiData";
import { Link, useNavigate } from "react-router-dom";

function OtpLogin() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");

  const showSuccess = (resMessage) => {
    Swal.fire({
      title: "Success",
      text: resMessage,
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const showError = (message) => {
    Swal.fire({
      title: "Error",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    });
  };
  const OtpHandler = async (e) => {
    e.preventDefault();
    if (phone === "") {
      showError("Please fill in all fields!");
    } else {
      try {
        const res = await ApiData.post(
          "/loginrequestotp",
          { phone },
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
        if (res.status === 201) {
          showSuccess(res.data.message);
        } else {
          showError(res.data.message);
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          // if the error response has a message property, display it to the user
          showError(err.response.data.message);
        } else {
          // if the error response does not have a message property, display a generic error message
          showError('An error occurred. Please try again later.');
        }}
    }
  };
  const [currentUser, setCurrentUser] = useState(null);
  const LoginHandler = async (e) => {
    e.preventDefault();
    if (phone === "" || otp === "") {
      showError("Please fill in all fields!");
    } else {
      try {
        const res = await ApiData.post(
          "/loginwithotp",
          {
            phone,
            otp,
          },
          {
            headers: {
              "access-control-allow-origin": "*",
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
        if (res.status === 202) {
          const { token, first_name, last_name,userData, user_id } = res.data; // destructure user data
          localStorage.setItem("token", token);
          localStorage.setItem("phone", phone);
          localStorage.setItem("first_name", first_name);
          localStorage.setItem("last_Name", last_name);
          localStorage.setItem("user_id", user_id);
          showSuccess("Login success");
          setCurrentUser(userData); // set currentUser to user data
          navigate("/dashboard");
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          // if the error response has a message property, display it to the user
          showError(err.response.data.message);
        } else {
          // if the error response does not have a message property, display a generic error message
          showError('An error occurred. Please try again later.');
        
        }}
    }
  };
  return (
    <>
      <NavBar />
      <div className="h-full bg-white-100 w-full py-16 px-4">
        <div className="flex flex-col items-center justify-center">
          <div className="bg-white shadow rounded lg:w-1/3  md:w-1/2 w-full p-10 mt-16">
            <p
              tabIndex={0}
              role="heading"
              aria-label="Login to your account"
              className="text-2xl font-extrabold leading-6 text-gray-800"
            >
              Login to your account
            </p>
            <p className="text-sm mt-4 font-medium leading-none text-gray-500">
              Don't have account?{`/`}
              <Link
                to={`/products`}
                className="text-sm font-medium leading-none underline text-gray-800 cursor-pointer"
              >
                Click here to create a new profile
              </Link>{" "}
              
            </p>

            <div className="pt-4">
              <label className="text-sm font-medium leading-none text-gray-800">
                PHONE NUMBER
              </label>
              <input
                aria-label="enter email adress"
                id="userId"
                name="userId"
                type="text"
                value={phone}
                autoComplete="userId"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
              />
            </div>
            <div className="mt-8">
              
              <button 
              onClick={OtpHandler}
              className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded hover:bg-indigo-600 py-4 w-1/4"
              >
                GET OTP
              </button>
            </div>
            <div className="mt-6  w-full">
              <label className="text-sm font-medium leading-none text-gray-800">
                OTP
              </label>
              <div className="relative flex items-center justify-center">
                <input
                  aria-label="Enter OTP"
                  role="input"
                  type="text"
                  id="otp"
                  name="otp"
                  value={otp}
                  autoComplete="otp"
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  className="bg-gray-200 border rounded focus:outline-none text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                />
              </div>
            </div>
            <div className="mt-8">
              <button
                role="button"
                onClick={LoginHandler}
                aria-label="create my account"
                className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-indigo-700 border rounded hover:bg-indigo-600 py-4 w-full"
              >
                LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OtpLogin;
