import React from "react";
import { Footer } from "../components/Footer";
import NavBar from "../components/NavBar";

export const Consern = () => {
  return (
    <div className="bg-orange-300 bg-opacity-60">
      <NavBar />
      <div className="2xl:mx-auto 2xl:container ">
        <div className=" w-full  relative flex items-center justify-center">
          <div className="py-10 w-full md:mx-24 md:px-12 px-4 flex flex-col items-center justify-center relative z-40">
            <h1 className="text-4xl font-semibold leading-9 text-gray text-center">
              PATIENT CONSENT FORM
            </h1>
            <h2>FOR COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION</h2>
            <p className="text-xl text-base leading-normal text-left text-gray mt-6">
              <h1 className="text-xl  leading-9 text-gray  pt-4">
                Telomr (“we” or “us”) is a complete cancer care aggregator
                system designed to revolutionize the way new technologies are
                adopted in cancer treatment. We are a network-based platform
                that uses customer mapping with market optimization tools to
                create a seamless marketplace accessible to masses at subsidized
                and affordable cancer care treatments across India. To
                facilitate the value-added services to our end user/cancer
                patients we shall collect, retain medical and personal data we
                hold about you, and you have a right to request information
                about that data from us (including to access and verify that
                data). We would like your informed consent to hold medical and
                personal data that you provide to us. All such personal data
                will be treated by us as strictly confidential.
              </h1>
              <h1 className="text-xl  leading-9 text-gray  pt-4">
               
                I hereby voluntarily give my
                written permission to have the 
                (Doctor/hospital) to release all pertinent
                medical, social, psychological or psychiatric information which
                pertains to my case. I agree to disclose personal information to
                Telomr so they can help me access the services I need. I
                understand that this information will be stored electronically
                on Telomr’s internal database. I agree for my information to be
                used anonymously as part of statistical or research reports, and
                in anonymous case studies.
              </h1>
              <h1 className="text-xl  leading-9 text-gray  pt-4">
                I agree to be contacted by Telomr by text message about upcoming
                health events like medical screenings.
                <br />
                I agree to be contacted by Telomr by telephone to collect
                feedback about the service I have received. <br />
                I agree to hold the Telomr harmless of all legal, financial, and
                any other liability that includes their agents, employees,
                successors and assigns, and their respective heirs, personal
                representatives, affiliates, successors and assigns, and any and
                all persons, firms or corporations liable or who might be
                claimed to be liable, whether or not herein named, none of whom
                admit any liability to the undersigned, but all expressly
                denying liability, from any and all claims, demands, damages,
                actions, causes of action or suits of any kind or nature
                whatsoever, which have or may hereafter have, arising out of or
                in any way relating to any and all injuries and damages of any
                and every kind, to both person and property, and also any and
                all injuries and damages that may develop in the future, as a
                result of or in any way relating to the permissible acts herein.
                <br />I furthermore release all parties stated here within from
                any liability resulting from the release of this information,
                with the understanding that all parties involved will exercise
                sufficient safeguards while using this information.
              </h1>
              <h1 className="text-xl font-semibold leading-9 text-gray  pt-4">
                Disclosure
              </h1>
              <ul className="list-disc pl-16 text-xl">
                Telomr understands the importance of protecting your personal
                information. To help you understand how we are doing that, we
                have outlined here how our office is using and disclosing your
                information. We will collect, use and disclose information about
                you for the following purposes:
                <li>to deliver safe and efficient patient care </li>
                <li>
                  to identify and to ensure continuous high-quality service{" "}
                </li>
                <li>to assess your health needs </li>
                <li>to provide affordable health care </li>
                <li>to advise you of treatment options </li>
                <li>to enable us to contact you </li>
                <li>to establish and maintain communication with you </li>
                <li>
                  to offer and provide treatment, care and services in
                  relationship cancer care treatment facilities
                </li>
                <li>
                  to communicate with other treating health-care providers,
                  including specialists{" "}
                </li>
                <li>
                  to allow us to maintain communication and contact with you to
                  distribute health-care information and to book and confirm
                  appointments{" "}
                </li>
                <li>
                  to allow us to efficiently follow-up for treatment, care and
                  billing{" "}
                </li>
                <li>
                  for teaching and demonstrating purposes on an anonymous basis{" "}
                </li>
                <li>
                  to complete and submit medical claims for third party
                  adjudication and payment{" "}
                </li>
                <li>to comply with legal and regulatory requirements. </li>
                <li>to comply generally with the law</li>
              </ul>
              <h2 className="text-xl  leading-9 text-gray  pt-4">
                By signing the consent section of this Patient Consent Form, you
                have agreed that you have given your informed consent to the
                collection, use and/or disclosure of your personal information
                for the purposes that are listed. If a new purpose arises for
                the use and/or disclosure of your personal information, we will
                seek your approval in advance You may withdraw your consent for
                use or disclosure of your personal information, and we will
                explain the ramifications of that decision, and the process.
              </h2>
              <h1 className="text-xl font-semibold leading-9 text-gray  pt-4">
                Patient Consent
              </h1>
              <h1 className="text-xl  leading-9 text-gray  pt-4">
              I have reviewed the above information that explains how Telomr
              will use my personal information, and the steps your office is
              taking to protect my information. </h1>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
