import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

function PatientsDetailsForm() {
  const [open7, setOpen7] = useState(false);
  const handleOpen7 = () => setOpen7(!open7);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(!open1);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(!open2);
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(!open3);
  const [open4, setOpen4] = useState(false);
  const handleOpen4 = () => setOpen4(!open4);
  const [user, setUser] = useState(null);
  const [open5, setOpen5] = useState(false);
  const handleOpen5 = () => setOpen5(!open5);

  const [open6, setOpen6] = useState(false);
  const handleOpen6 = () => setOpen6(!open6);

   const [open8, setOpen8] = useState(false);
  const handleOpen8 = () => setOpen8(!open8);

  const [open9, setOpen9] = useState(false);
  const handleOpen9 = () => setOpen9(!open9);

  const [open10, setOpen10] = useState(false);
  const handleOpen10 = () => setOpen10(!open10);
  
  const [open11, setOpen11] = useState(false);
  const handleOpen11 = () => setOpen11(!open11);
  
  const [open12, setOpen12] = useState(false);
  const handleOpen12 = () => setOpen12(!open12);

  const [open13, setOpen13] = useState(false);
  const handleOpen13 = () => setOpen13(!open13);

  const [open14, setOpen14] = useState(false);
  const handleOpen14 = () => setOpen14(!open14);

  
  const [open16, setOpen16] = useState(false);
  const handleOpen16 = () => setOpen16(!open16);
  
  const phone = localStorage.getItem('phone');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [patient_name, setPatient_name] = useState('');
  const [patient_dob, setPatient_dob] = useState('');
  const [consulting_physicion, setConsulting_physicion] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [occupation, setOccupation] = useState("");
  const [salary, setSalary] = useState("");
  const [p_address, setP_address] = useState("");
  const [date_of_diagnosis, setDate_of_diagnosis] = useState("");
  const [hospital_name, setHospital_name] = useState("");
  const [cancer, setCancer] = useState("");
  const [stage_of_cancer, setStage_of_cancer] = useState("");
  const [myplan, setMyplan] = useState("");
  const [insurance, setInsurance] = useState("");
  const [state, setState] = useState("");
  const [user_id, setUser_id] = useState("")
  const[country, setCountry] = useState("")

  

  useEffect(() => {
    axios.get(`http://localhost:4000/user/userinfo/?phone=${phone}`)
      .then(response => {
        const userData = response.data.Result[0]; // Get the user data from the "Result" array
        setUser(userData);
        setFirst_name(userData.first_name);
        setLast_name(userData.last_name);
        setPatient_dob(userData.patient_dob);
        setConsulting_physicion(userData.consulting_physicion);
        setPatient_name(userData.patient_name);
        setCity(userData.city);
        setRegion(userData.region);
        setPostal_code(userData.postal_code);
        setOccupation(userData.occupation);
        setSalary(userData.salary);
        setP_address(userData.p_address);
        setDate_of_diagnosis(userData.date_of_diagnosis);
        setHospital_name(userData.hospital_name);
        setCancer(userData.cancer);
        setStage_of_cancer(userData.stage_of_cancer);
        setInsurance(userData.insurance);
        setMyplan(userData.myplan);
        setState(userData.state);
        setUser_id(userData.user_id);
        setCountry(userData.country);

      })
      .catch(error => {
        console.error(error);
      });
  }, [phone]);

  const handleEmailChange = (event) => {
    setPatient_name(event.target.value);
  };
  const handleconsultingphysicionChange = (event) => {
    setConsulting_physicion(event.target.value);
  };

  const handlecityChange = (event) => {
    setCity(event.target.value);
  };

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };
  const handleUserChange = (event) => {
    setUser_id(event.target.value);
  };

  const handlePostal_codeChange = (event) => {
    setPostal_code(event.target.value);
  };

  const handleOccupationChange = (event) => {
    setOccupation(event.target.value);
  };

  const handleSalaryChange = (event) => {
    setSalary(event.target.value);
  };

  const handleP_addressChange = (event) => {
    setP_address(event.target.value);
  };

  const handlesetDate_of_diagnosisChange = (event) => {
    setDate_of_diagnosis(event.target.value);
  };

  const handleHospital_nameChange = (event) => {
    setHospital_name(event.target.value);
  };

  const handleCancerChange = (event) => {
    setCancer(event.target.value);
  };

  const handleStage_of_cancerChange = (event) => {
    setStage_of_cancer(event.target.value);
  };

  const handleInsuranceChange = (event) => {
    setInsurance(event.target.value);
  };



  const handleMyplanChange = (event) => {
    setMyplan(event.target.value);
  };

  
  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

 
  const handleDateOfBirthChange = (event) => {
    setPatient_dob(event.target.value);
  };




  const handleUpdateClick = (event, field) => {
    event.preventDefault();
  
    let data = {};
    if (field === 'first_name') {
      data = { first_name: first_name };
    } else if (field === 'last_name') {
      data = { last_name: last_name };
    }else if (field === 'patient_name') {
      data = { patient_name: patient_name };
    }else if (field === 'user_id') {
      data = { user_id: user_id };
    } else if (field === 'patient_dob') {
      data = { patient_dob: patient_dob };
    }else if (field === 'consulting_physicion') {
      data = { consulting_physicion: consulting_physicion };
    }
     else if (field === 'city') {
    data = { city: city };
  }

else if (field === 'region') {
  data = { region: region };
}
else if (field === 'postal_code') {
  data = { postal_code: postal_code };
}
else if (field === 'occupation') {
  data = { occupation: occupation };
}
else if (field === 'salary') {
  data = { salary: salary };
}
else if (field === 'p_address') {
  data = { p_address: p_address };
}
else if (field === 'date_of_diagnosis') {
  data = { date_of_diagnosis: date_of_diagnosis };
}
else if (field === 'hospital_name') {
  data = { hospital_name: hospital_name };
}
else if (field === 'cancer') {
  data = { cancer: cancer };
}
else if (field === 'stage_of_cancer') {
  data = { stage_of_cancer: stage_of_cancer };
}
else if (field === 'insurance') {
  data = { insurance: insurance };
}
else if (field === 'myplan') {
  data = { myplan: myplan };
}
else if (field === 'state') {
  data = { state: state };
}

else if (field === 'country') {
  data = { country: country };
}




    axios.put(`http://localhost:4000/user/update/${phone}`, data)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      });
  };
  

  if (!user) {
    return <p>Loading user details...</p>;
  }

  return (
    <table className="w-full text-xl">
      <thead>
        {/* <tr>
          <th className='px-32'></th>
          <th className='px-32'></th>
          <th></th>
        </tr> */}

      </thead>
      <tbody>
      
        <tr className="border-b-2">
  <td className="py-2">Patient Name</td>
  <td className="py-2">{patient_name}</td>
  <td className="py-2">
    <Fragment>
      <Button
        onClick={handleOpen}
        variant="gradient"
        className="text-black items-right"
        disabled={patient_name !== ''}
      >
        Edit
      </Button>
      <Dialog
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader>Update your Profile</DialogHeader>
        <form onSubmit={(event) => handleUpdateClick(event, 'patient_name')}>
          <DialogBody divider>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Patients Name {" "}
              </label>
              <input
                type="text"
                value={patient_name} onChange={handleEmailChange} 
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button
              variant="gradient"
              color="green"
              type="submit"
              onClick={handleOpen}
              className="text-black"
            >
              <span>Update</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
    </Fragment>
  </td>
</tr>

<tr className="border-b-2">
          <td className="py-2">Patient's Date of Birth</td>
          <td className="py-2">{patient_dob}</td>
          <td className="py-2">
          <Fragment>
    <Button
      onClick={handleOpen14}
      variant="gradient"
      className="text-black items-right"
    >
      Edit
    </Button>
    <Dialog
      open={open14}
      handler={handleOpen14}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Update your Profile</DialogHeader>
      <form  onSubmit={(event) => handleUpdateClick(event, 'patient_dob')}>
      <DialogBody divider>
        <div>
          <lable className="block text-sm font-medium text-gray-700">
          date of birth{" "}
          </lable>
          <input type="date" value={patient_dob} onChange={handleDateOfBirthChange} 
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen14}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          type="submit"
          onClick={handleOpen14}
          className="text-black"
        >
          <span>Update</span>
        </Button>
      </DialogFooter>
      </form>
    </Dialog>
  </Fragment>
           
          </td>
        </tr>
        
        
        {/* <tr className="border-b-2">
          <td className="py-2">City:</td>
          <td className="py-2">{city}</td>
          <td className="py-2">
             <Fragment>
    <Button
      onClick={handleOpen1}
      variant="gradient"
      className="text-black items-right"
    >
      Edit
    </Button>
    <Dialog
      open={open1}
      handler={handleOpen1}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Update your Profile</DialogHeader>
      <form  onSubmit={(event) => handleUpdateClick(event, 'city')}>
      <DialogBody divider>
        <div>
          <lable className="block text-sm font-medium text-gray-700">
            City{" "}
          </lable>
          <input
            type="text"
            value={city} onChange={handlecityChange} 
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen1}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          type="submit"
          onClick={handleOpen1}
          className="text-black"
        >
          <span>Update</span>
        </Button>
      </DialogFooter>
      </form>
    </Dialog>
  </Fragment>
          </td>
        </tr> */}
        <tr className="border-b-2">
          <td className="py-2">Consulting Physician</td>
          <td className="py-2">{consulting_physicion}</td>
          <td className="py-2">
             <Fragment>
    <Button
      onClick={handleOpen7}
      variant="gradient"
      className="text-black items-right"
    >
      Edit
    </Button>
    <Dialog
      open={open7}
      handler={handleOpen7}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Update your Profile</DialogHeader>
      <form  onSubmit={(event) => handleUpdateClick(event, 'consulting_physicion')}>
      <DialogBody divider>
        <div>
          <lable className="block text-sm font-medium text-gray-700">
            Consulting physician{" "}
          </lable>
          <input
            type="text"
            value={consulting_physicion} onChange={handleconsultingphysicionChange} 
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen7}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          type="submit"
          onClick={handleOpen7}
          className="text-black"
        >
          <span>Update</span>
        </Button>
      </DialogFooter>
      </form>
    </Dialog>
  </Fragment>
          </td>
        </tr>

          <tr className="border-b-2">
          <td className="py-2">Date of diagnosis</td>
          <td className="py-2">{date_of_diagnosis}</td>
          <td className="py-2">
             <Fragment>
    <Button
      onClick={handleOpen8}
      variant="gradient"
      className="text-black items-right"
    >
      Edit
    </Button>
    <Dialog
      open={open8}
      handler={handleOpen8}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Update your Profile</DialogHeader>
      <form  onSubmit={(event) => handleUpdateClick(event, 'date_of_diagnosis')}>
      <DialogBody divider>
        <div>
          <lable className="block text-sm font-medium text-gray-700">
          Date of Diagnosis {" "}
          </lable>
          <input
            type="date"
            value={date_of_diagnosis} onChange={handlesetDate_of_diagnosisChange} 
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen8}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          type="submit"
          onClick={handleOpen8}
          className="text-black"
        >
          <span>Update</span>
        </Button>
      </DialogFooter>
      </form>
    </Dialog>
  </Fragment>
          </td>
          </tr>{/*    date_of_diagnosis */}
          <tr className="border-b-2">
          <td className="py-2">Hospital Name</td>
          <td className="py-2">{hospital_name}</td>
          <td className="py-2">
             <Fragment>
    <Button
      onClick={handleOpen9}
      variant="gradient"
      className="text-black items-right"
    >
      Edit
    </Button>
    <Dialog
      open={open9}
      handler={handleOpen9}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Update your Profile</DialogHeader>
      <form  onSubmit={(event) => handleUpdateClick(event, 'hospital_name')}>
      <DialogBody divider>
        <div>
          <lable className="block text-sm font-medium text-gray-700">
          Hospital Name  {" "}
          </lable>
          <input
            type="text"
            value={hospital_name} onChange={handleHospital_nameChange} 
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen9}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          type="submit"
          onClick={handleOpen9}
          className="text-black"
        >
          <span>Update</span>
        </Button>
      </DialogFooter>
      </form>
    </Dialog>
  </Fragment>
          </td>
          </tr>{/*    hospital_name */}
          <tr className="border-b-2">
          <td className="py-2">Type of Cancer </td>
          <td className="py-2">{cancer}</td>
          <td className="py-2">
             <Fragment>
    <Button
      onClick={handleOpen10}
      variant="gradient"
      className="text-black items-right"
    >
      Edit
    </Button>
    <Dialog
      open={open10}
      handler={handleOpen10}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Update your Profile</DialogHeader>
      <form  onSubmit={(event) => handleUpdateClick(event, 'cancer')}>
      <DialogBody divider>
        <div>
          <lable className="block text-sm font-medium text-gray-700">
          Type of Cancer  {" "}
          </lable>
          <input
            type="text"
            value={cancer} onChange={handleCancerChange} 
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen10}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          type="submit"
          onClick={handleOpen10}
          className="text-black"
        >
          <span>Update</span>
        </Button>
      </DialogFooter>
      </form>
    </Dialog>
  </Fragment>
          </td>
          </tr>{/*    cancer */}
          <tr className="border-b-2">
          <td className="py-2">Stage of Cancer </td>
          <td className="py-2">{stage_of_cancer}</td>
          <td className="py-2">
             <Fragment>
    <Button
      onClick={handleOpen11}
      variant="gradient"
      className="text-black items-right"
    >
      Edit
    </Button>
    <Dialog
      open={open11}
      handler={handleOpen11}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Update your Profile</DialogHeader>
      <form  onSubmit={(event) => handleUpdateClick(event, 'stage_of_cancer')}>
      <DialogBody divider>
        <div>
          <lable className="block text-sm font-medium text-gray-700">
          Stage of Cancer  {" "}
          </lable>
          <input
            type="text"
            value={stage_of_cancer} onChange={handleStage_of_cancerChange} 
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen11}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          type="submit"
          onClick={handleOpen11}
          className="text-black"
        >
          <span>Update</span>
        </Button>
      </DialogFooter>
      </form>
    </Dialog>
  </Fragment>
          </td>
          </tr>{/*    stage_of_cancer */}
          <tr className="border-b-2">
          <td className="py-2">Insurance </td>
          <td className="py-2">{insurance}</td>
          <td className="py-2">
             <Fragment>
    <Button
      onClick={handleOpen12}
      variant="gradient"
      className="text-black items-right"
    >
      Edit
    </Button>
    <Dialog
      open={open12}
      handler={handleOpen12}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
    >
      <DialogHeader>Update your Profile</DialogHeader>
      <form  onSubmit={(event) => handleUpdateClick(event, 'insurance')}>
      <DialogBody divider>
        <div>
          <lable className="block text-sm font-medium text-gray-700">
          Insurance  {" "}
          </lable>
          <input
            type="text"
            value={insurance} onChange={handleInsuranceChange} 
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          variant="text"
          color="red"
          onClick={handleOpen12}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="gradient"
          color="green"
          type="submit"
          onClick={handleOpen12}
          className="text-black"
        >
          <span>Update</span>
        </Button>
      </DialogFooter>
      </form>
    </Dialog>
  </Fragment>
          </td>
          </tr>{/*    insurance */}
               
      </tbody>
    </table>
   
  );
}

export default PatientsDetailsForm;
