import React from "react";
import { Footer } from "../components/Footer";
import NavBar from "../components/NavBar";

export const Disclouser = () => {
  return (
    <div>
      <NavBar />
      <div className="2xl:mx-auto 2xl:container ">
        <div className=" w-full  relative flex items-center justify-center">
          <div className="bg-orange-300 bg-opacity-60 md:my-16 lg:py-16 py-10 w-full md:mx-24 md:px-12 px-4 flex flex-col items-center justify-center relative z-40">
            <h1 className="text-4xl font-semibold leading-9 text-gray text-center">
              PATIENT CONSENT FORM
            </h1>
            <h1 className="text-2xl font-semibold leading-9 text-gray text-center pt-4">
              FOR COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION
            </h1>
            <p className="text-xl text-base leading-normal text-left text-gray mt-6">
              Telomr (“we” or “us”) is a complete cancer care aggregator system
              designed to revolutionize the way new technologies are adopted in
              cancer treatment. We are a network-based platform that uses
              customer mapping with market optimization tools to create a
              seamless marketplace accessible to masses at subsidized and
              affordable cancer care treatments across India.<br></br>
              <br></br>
              To facilitate the value-added services to our end user/cancer
              patients we shall collect, retain medical and personal data we
              hold about you, and you have a right to request information about
              that data from us (including to access and verify that data).
              <br></br>
              <br></br>
              We would like your informed consent to hold medical and personal
              data that you provide to us. All such personal data will be
              treated by us as strictly confidential.<br></br>
              <br></br>
              I, hereby voluntarily give my permission to have the
              (Doctor/hospital) release all pertinent medical, social,
              psychological or psychiatric information which pertains to my
              case. <br></br>
              <br></br>I agree to disclose personal information to Telomr so
              they can help me access the services I need. I understand that
              this information will be stored electronically on Telomr’s
              internal database.<br></br>
              <br></br>I agree for my information to be used anonymously as part
              of statistical or research reports, and in anonymous case studies.
              <br></br>
              <br></br>
              <h1 className="text-xl font-bold leading-9 text-gray text-center">
                *In compliance with Information Technology Act 2000, (Reasonable
                security practices and procedures and sensitive personal data or
                information rules 2011)
              </h1>
              I agree to be contacted by Telomr by text message about upcoming
              health events like medical screenings.<br></br>
              <br></br>I agree to be contacted by Telomr by telephone to collect
              feedback about the service I have received. <br></br>
              <br></br>I agree to hold the Telomr harmless of all legal,
              financial, and any other liability that includes their agents,
              employees, successors and assigns, and their respective heirs,
              personal representatives, affiliates, successors and assigns, and
              any and all persons, firms or corporations liable or who might be
              claimed to be liable, whether or not herein named, none of whom
              admit any liability to the undersigned, but all expressly denying
              liability, from any and all claims, demands, damages, actions,
              causes of action or suits of any kind or nature whatsoever, which
              have or may hereafter have, arising out of or in any way relating
              to any and all injuries and damages of any and every kind, to both
              person and property, and also any and all injuries and damages
              that may develop in the future, as a result of or in any way
              relating to the permissible acts herein.<br></br>
              <br></br>I furthermore release all parties stated here within from
              any liability resulting from the release of this information, with
              the understanding that all parties involved will exercise
              sufficient safeguards while using this information.<br></br>
              <br></br>
              <h1 className="text-2xl font-bold leading-9 text-gray text-center">
                Disclosure
              </h1>
              Telomr understands the importance of protecting your personal
              information. To help you understand how we are doing that, we have
              outlined here how our office is using and disclosing your
              information. We will collect, use and disclose information about
              you for the following purposes:<br></br>
              <br></br>∙ to deliver safe and efficient patient care <br></br>
              <br></br>∙ to identify and to ensure continuous high-quality
              service <br></br>
              <br></br>∙ to assess your health needs <br></br>
              <br></br>∙ to provide affordable health care <br></br>
              <br></br>∙ to advise you of treatment options <br></br>
              <br></br>∙ to enable us to contact you <br></br>
              <br></br>∙ to establish and maintain communication with you{" "}
              <br></br>
              <br></br>∙ to offer and provide treatment, care and services in
              relationship cancer care treatment facilities<br></br>
              <br></br>∙ to communicate with other treating health-care
              providers, including specialists <br></br>
              <br></br>∙ to allow us to maintain communication and contact with
              you to distribute health-care information and to book and confirm
              appointments <br></br>
              <br></br>∙ to allow us to efficiently follow-up for treatment,
              care and billing <br></br>
              <br></br>∙ for teaching and demonstrating purposes on an anonymous
              basis <br></br>
              <br></br>∙ to complete and submit medical claims for third party
              adjudication and payment <br></br>
              <br></br>∙ to comply with legal and regulatory requirements.{" "}
              <br></br>
              <br></br>∙ to comply generally with the law <br></br>
              <br></br>
              By signing the consent section of this Patient Consent Form, you
              have agreed that you have given your informed consent to the
              collection, use and/or disclosure of your personal information for
              the purposes that are listed. If a new purpose arises for the use
              and/or disclosure of your personal information, we will seek your
              approval in advance<br></br>
              <br></br>
              You may withdraw your consent for use or disclosure of your
              personal information, and we will explain the ramifications of
              that decision, and the process.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
